import React, { Component } from 'react';
import styled from 'styled-components';
import Close from '../../images/close.png';
import Message from '../../images/message.png';

const Div=styled.div`
    position: fixed;
    top:0;
    left:0;
    bottom:0;
    right:0;
    background: rgba(0,0,0,.5);
    display: flex;
	flex-flow:row wrap;
	justify-content:center;
    align-items:center;
    z-index:10;
    overflow: auto;
    .notice-detail{
        width: 560px;
        min-height: 340px;
        background:#fff;
        padding-bottom: 20px;
        box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.47);
        border-radius:10px;
        .notice-close{
            img{
                float: right;
                margin:14px;
                cursor: pointer;
            }
        }
        .notice-message-img{
            text-align:center;
            padding-top:30px; 
        }
        .inform-title{
            margin-top:10px;
            font-size:23px;
            line-height: 50px;
            text-align:center;
            color:#000; 
        }
        .notice-detail-content{
            // text-align:center; 
            padding:0 20px;
            color:#666;
        }
    }
`

class ProjectNotice extends Component{
    constructor(props){
        super(props);
        this.state={

        }
    }
    componentDidMount(){
        document.body.style.overflow="hidden";
    }
    componentWillUnmount(){
        this.setState=(state,callback)=>{
            return
        }
        document.body.style.overflow="unset";
    }
    render(){
        const detail=this.props.detail;
        return <Div>
            <div className="notice-detail">
                <div className="notice-close">
                    <img onClick={this.props.close} src={Close} alt=""/>
                </div>
                <div className="notice-message-img">
                    <img src={Message} alt=""/>
                </div>
                <div className="inform-title">
                    {detail.otype===1?"漏洞提交公告":"项目系统公告"}
                </div>
                <div></div>
                <div className="notice-detail-content">
                {detail.create_time}，{detail.content}
                </div>
            </div>
        </Div>
    }
}
export default ProjectNotice