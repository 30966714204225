import React, { Component } from 'react';
import styled from 'styled-components';
import Project from '../../images/project.png';
import Rank from '../../images/rank.png';
import Vuln from '../../images/vuln.png';
import ProjectHeader from '../../images/project-header.png';
import {toThousands} from '../toThousands';
import Config from '../../json/config.json';
import history from '../history';
import Fetch from '../Fetch';
import {Alert} from '../toThousands';
import RealmName from '../../json/realmName.json';

const Div=styled.div`
    .project-header{
        background: #fff;
        height: 150px;
        .project-header-content{
            width:1200px;
            margin:0 auto;
            .patternmaking{
                vertical-align:middle; 
                float: right;
                img{
                    vertical-align:middle;
                    margin-top:10px; 
                }
            }
        }
        .data-display{
            margin-top: 26px;
            display: inline-block;
            vertical-align:middle; 
            .data-detail{
                display: inline-block;
                vertical-align:middle; 
                // margin:0 60px;
                width: 330px;
                img{
                    vertical-align:middle; 
                }
                .content{
                    padding-left:20px;
                    display: inline-block;
                    vertical-align:middle; 
                    .number{
                        color:${props => {
                            return Config.colour
                        }}
                        font-size:20px;
                        line-height:30px; 
                    }
                    p{
                        color:#999;
                        font-size:16px;
                    }
                }
            }
        }
    }
    .project-list{
        width:1200px;
        margin:0 auto;
        // margin-top:20px;
    }
    .item-project>.project-detail>.intro>pre{
        margin-top:10px;
        font-size:12px;
        line-height: 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
        height:120px;
        word-break: break-all;
        white-space: pre-wrap;
    }
    .project-screen{
        .content{
            width: 1200px;
            background:#fff;
            margin:30px auto;
            min-height:50px; 
            padding:10px 20px;
            border-radius:5px;
            box-sizing:border-box;
            .screen-type{
                padding:10px 0;
                .name{
                    font-size:16px;
                    display: inline-block;
                    vertical-align:top; 
                }
                b{
                    cursor: pointer;
                    color:${props=>{
                        return Config.colour
                    }}
                    font-size:15px;
                    white-space:nowrap;
                }
                .change-type{
                    display: inline-block;
                    vertical-align:top;
                    width: 1100px;
                    span{
                        cursor: pointer;
                        margin-right:20px;
                        display: inline-block;
                        font-size:16px;
                        white-space:nowrap;
                        :hover{
                            color:${props=>{
                                return Config.colour
                            }}
                        }
                    }
                    .active{
                        color:${props=>{
                            return Config.colour
                        }}
                    }
                }
            }
        }
    }
    .herf-link>span{
        color:${props=>{
            return Config.colour
        }};
        cursor: pointer;
    }
`
function getClientHeight() {
    var clientHeight = 0;
    if (document.body.clientHeight && document.documentElement.clientHeight) {
        clientHeight = (document.body.clientHeight < document.documentElement.clientHeight) ? document.body.clientHeight : document.documentElement.clientHeight;
    } else {
        clientHeight = (document.body.clientHeight > document.documentElement.clientHeight) ? document.body.clientHeight : document.documentElement.clientHeight;
    }
    return clientHeight;
}
function getScrollTop() {
    var scrollTop = 0;
    if (document.documentElement && document.documentElement.scrollTop) {
        scrollTop = document.documentElement.scrollTop;
    } else if (document.body) {
        scrollTop = document.body.scrollTop;
    }
    return scrollTop;
}
class ProjectList extends Component{
    constructor(props){
        super(props)
        this.state={
            Statistics:{},
            Classify:[],
            showClassify:[],
            allTag:[],
            showTag:[],
            check:{
                categories:"",
                tag:""
            },
            filter:{
                size:16,
                page:1
            },
            projectData:[],
            allCount:0,
        }
    }
    componentDidMount() {
        this.getStatistics();
        this.getClassify();
        this.getTag();
        this.getProject();
        window.addEventListener('scroll', this.scorll.bind(this));
    }
    componentWillUnmount = () => {
        this.setState=(state,callback)=>{
            return
        }
        window.removeEventListener("scroll", this.scorll.bind(this),false);
    };
    //获取项目列表
    getProject(){
        let check=this.state.check;
        let filter=this.state.filter;
        let str="page="+filter.page+"&size="+filter.size;
        if(check.categories!==""){
            str+="&categories="+check.categories;
        }
        if(check.tag!==""){
            str+="&tags="+check.tag;
        }
        Fetch.get("/ptp/project/list/?"+str).then(data=>{
            this.setState({
                projectData:data.results,
                allCount:data.count
            })
        })
    }
    getStatistics(){
        Fetch.get("/ptp/system/config/").then(data=>{
            this.setState({
                Statistics:data
            })
        })
    }
    getClassify(){
        Fetch.get("/ptp/project/categories/?nopage=0").then(data=>{
            let showClassify=[];
            if(data.length>10){
                for(var i=0;i<10;i++){
                    showClassify.push(data[i])
                }
            }else{
                showClassify=data;
            }
            this.setState({
                showClassify:showClassify,
                Classify:data
            })
        })
    }
    getTag(){
        Fetch.get("/ptp/project/tags/?nopage=0").then(data=>{
            let showTag=[];
            if(data.length>10){
                for(var i=0;i<10;i++){
                    showTag.push(data[i])
                }
            }else{
                showTag=data;
            }
            this.setState({
                showTag:showTag,
                allTag:data
            })
        })
    }
    //查看漏洞详情
    handleLookDetail(id,status,flag){
        if(status===1){
            Alert("warn","项目即将开始，请在指定时间段内参与项目！")
        }else{
            if(flag===5){
                history.push("/projexthall/detail/"+id)
            }
        }
    }
    //点击分类更多
    handleMoreClass(){
        let Classify=this.state.Classify;
        this.setState({
            showClassify:Classify,
        })
    }
    //点击分类收起
    handleUpClass(){
        let Classify=this.state.Classify;
        let showClassify=[];
        for(var i=0;i<10;i++){
            showClassify.push(Classify[i])
        }
        this.setState({
            showClassify:showClassify
        })
    }
    //点击标签更多
    handleMoreTag(){
        let allTag=this.state.allTag;
        this.setState({
            showTag:allTag,
        })
    }
    handleUpTag(){
        let allTag=this.state.allTag;
        let showTag=[];
        for(var i=0;i<10;i++){
            showTag.push(allTag[i])
        }
        this.setState({
            showTag:showTag
        })
    }
    //点击选择分类或标签 0 分类 1标签
    checkClass(type,id){
        let check=this.state.check;
        let filter=this.state.filter;
        filter.size=16;
        if(type===0){
            check.categories=id;
        }else{
            check.tag=id
        }
        this.setState({
            check:check,
            filter:filter
        },()=>{
            this.getProject();
        })
    }
    //简介未登录不能查看，点击登录
    handleLogin(){
        let href=window.location.href.replace("#","@");
        window.location.href=window.location.protocol + "//" + window.location.host + "/client/?next="+href;
    }
    //点击进行认证，跳转到哦个人中心
    handleApprove(){
        history.push("/userinfo")
    }
    //点击申请参与特邀项目
    handleApply(id,key){
        let projectData=this.state.projectData;
        Fetch.get("ptp/project/"+id+"/apply/").then(data=>{
            if(data.status===1){
                Alert("success","申请成功，请等待！");
                projectData[key].flag=8;
                this.setState({
                    projectData:projectData
                })
            }else{
                Alert("error",data.info)
            }
        })
    }
    scorll(){
        let filter=this.state.filter;
        let allCount=this.state.allCount;
        if (document.body.scrollHeight - getClientHeight() - getScrollTop() < 500) {
            if(filter.size<allCount){
                filter.size+=16
                this.setState({
                    filter:filter
                },()=>{
                    this.getProject();
                })
            }
        }
    }
    handleLink(){
        window.open(RealmName.yunyan)
    }
    render(){
        const Statistics=this.state.Statistics;
        const showClassify=this.state.showClassify;
        const Classify=this.state.Classify;
        const showTag=this.state.showTag;
        const allTag=this.state.allTag;
        const check=this.state.check;
        const projectData=this.state.projectData;
        return <Div>
            <div className="project-header">
                <div className="project-header-content">
                    <div className="data-display">
                        <div className="data-detail">
                            <img src={Project} alt=""/>
                            <div className="content">
                                <div className="number">{toThousands(Statistics.project_number)}</div>
                                <p>项目数量</p>
                            </div>
                        </div>
                        <div className="data-detail">
                            <img src={Vuln} alt=""/>
                            <div className="content">
                                <div className="number">{toThousands(Statistics.vuln_number)}</div>
                                <p>漏洞数量</p>
                            </div>
                        </div>
                        <div className="data-detail">
                            <img src={Rank} alt=""/>
                            <div className="content">
                                <div className="number">{toThousands(Statistics.payment_bouns)}</div>
                                <p>已发放赏金</p>
                            </div>
                        </div>
                    </div>
                    <div className="patternmaking">
                        <img src={ProjectHeader} alt=""/>
                    </div>
                </div>
            </div>
            <div className="project-screen">
                <div className="content">
                    <div className="screen-type">
                        <span className="name">分类：</span>
                        <div className="change-type">
                            <span className={check.categories===""?"active":""} onClick={this.checkClass.bind(this,0,"")}>所有项目</span>
                            {
                                showClassify.map((val,key)=>{
                                    return <span key={"key"+key} className={check.categories===val.id?"active":""} onClick={this.checkClass.bind(this,0,val.id)}>
                                        {val.name}
                                    </span>
                                })
                            }
                            {
                                Classify.length>10&&(Classify.length>showClassify.length?
                                <b className="more" onClick={this.handleMoreClass.bind(this)}>更多>></b>
                                :<b className="more" onClick={this.handleUpClass.bind(this)}>收起</b>)
                            }
                        </div>
                    </div>
                    <div className="screen-type">
                        <span className="name">标签：</span>
                        <div className="change-type">
                            <span className={check.tag===""?"active":""} onClick={this.checkClass.bind(this,1,"")}>全部</span>
                            {
                                showTag.map((val,key)=>{
                                    return <span key={"key"+key} className={check.tag===val.id?"active":""} onClick={this.checkClass.bind(this,1,val.id)}>
                                        {val.name}
                                    </span>
                                })
                            }
                            {
                                allTag.length>10&&(allTag.length>showTag.length?
                                <b className="more" onClick={this.handleMoreTag.bind(this)}>更多>></b>
                                :<b className="more" onClick={this.handleUpTag.bind(this)}>收起</b>)
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="project-list">
                {
                    projectData.map((val,key)=>{
                        return <div className="item-project" key={"key"+key}>
                        <div className="project-photo">
                            <img src={val.example_photo} alt=""/>
                            {/* not-online 即将上线class  underway 进行中  finished 已完成 */}
                            <span className={val.status===1?"not-online":val.status===2?"underway":"finished"}>{val.status===1?"即将上线":val.status===2?"进行中":"已结束"}</span>
                        </div>
                        <div className="project-content">
                            <div className="project-name" title={val.name}>{val.name}</div>
                            <div className="project-bonus">奖金池：{toThousands(val.bouns_pool)}</div>
                            <div className="project-time">期限：{val.start_time} - {val.end_time}</div>
                        </div>
                        <div className="project-detail">
                            <div className="project-name"  onClick={this.handleLookDetail.bind(this,val.id,val.status,val.flag)} title={val.name}>{val.name}</div>
                            <div className="project-bonus">奖金池：{toThousands(val.bouns_pool)}</div>
                            <div className="project-time">期限：{val.start_time} - {val.end_time}</div>
                            <div className="intro">
                                {
                                    val.flag===1&&
                                    <div>您未登录，请先<span style={{cursor:"pointer",color:Config.colour}} onClick={this.handleLogin.bind(this)}>登录</span>！</div>
                                }
                                {
                                    val.flag===2&&(val.status === 3 ? <div>项目已结束!</div> :
                                    <div>您未进行实名认证，请先<span style={{cursor:"pointer",color:Config.colour}} onClick={this.handleApprove.bind(this)}>认证</span>！</div>)
                                }
                                {
                                    val.flag===3&&(val.status === 3 ? <div>项目已结束!</div> :
                                    <div>您提交的实名认证信息正在审核，请等待！</div>)
                                }
                                {
                                    val.flag===4&&(val.status === 3 ? <div>项目已结束!</div> :
                                    <div>您提交的实名认证信息已被拒绝，请重新<span style={{cursor:"pointer",color:Config.colour}} onClick={this.handleApprove.bind(this)}>认证</span>！</div>)
                                }
                                {
                                    val.flag===5&&
                                    <pre title={val.brief}>
                                        {val.brief}
                                    </pre>
                                }
                                {
                                    val.flag === 6 && (val.status === 3 ? <div>项目已结束!</div> :
                                    <div className="herf-link">您需要在<span onClick={this.handleLink.bind(this)}>【云演】</span>平台完成学习,达到【LV{val.yunyan_level}】成就才能参与该项目，加油哟！</div>)
                                }
                                {
                                    val.flag === 7 && (val.status === 3 ? <div>项目已结束!</div> : <div>该项目为特邀项目，您无权限参与，请点击<span style={{ cursor: "pointer", color: Config.colour }} onClick={this.handleApply.bind(this, val.id, key)}>申请</span>参与！</div>)
                                }
                                {
                                    val.flag===8&&(val.status === 3 ? <div>项目已结束!</div> :
                                    <div>请稍等，正在审核中!</div>)
                                }
                                {
                                    val.flag===9&&(val.status === 3 ? <div>项目已结束!</div> :
                                    <div>您未通过申请，不好意思！</div>)
                                }
                                {
                                    val.flag===10&&(val.status === 3 ? <div>项目已结束!</div> :
                                    <div className="herf-link">请您先完成<span onClick={this.handleLink.bind(this)}>【云演】</span>的回炉学习任务，详情见帮助中心！</div>)
                                }
                            </div>
                        </div>
                    </div>
                    })
                }
            </div>
        </Div>
    }
}
export default ProjectList
