
import {notification, Icon } from 'antd';
import React from 'react';

// 处理数字间隔三个用逗隔开
let toThousands=(num)=>{
    var number = (num || 0).toString().split("."), result = '';
    var before=number[0];
    while (before.length > 3) {
        result = ',' + before.slice(-3) + result;
        before = before.slice(0, before.length - 3);
    }
    if (before) { result = before + result; }
    if(number.length>1){
        result=result+"."+number[1]
    }
    return result;
}
const openNotification = (title,icon,className) => {
    notification.open({
      message: title,
    //   description:description,
      icon: icon,
      className:className
    });
}
//提示信息
let Alert=(type,description)=>{
    if(type==="success"){
        const successIcon=<Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" style={{fontSize:"25px"}}/>
        openNotification(description,successIcon,"success-alert");
    }else if(type==="error"){
        const errorIcon=<Icon type="close-circle" theme="twoTone" twoToneColor="#f5222d"  style={{fontSize:"25px"}}/>
        openNotification(description,errorIcon,"error-alert");
    }else if(type==="warn"){
        const warnIcon=<Icon type="exclamation-circle" theme="twoTone" twoToneColor="#faad14" style={{fontSize:"25px"}} />
        openNotification(description,warnIcon,"warn-alert");
    }
}
export {
    toThousands,
    Alert
};