import React, { Component } from 'react';
import styled from 'styled-components';
import { Avatar } from 'antd';
import Config from '../../json/config.json';
import First from '../../images/first.png';
import Second from '../../images/second.png';
import Third from '../../images/third.png';

const Div=styled.div`
    height: 600px;
    table{
        width: 100%;
        text-align:center;
        border-bottom:1px solid #eaeaea;
        
        thead{
            background:#f5f5f5;
            th{
                font-size:16px;
            }
        } 
        tr{
            line-height:50px; 
            border-top:1px solid #eaeaea;
            text-align:center;
            td{
                .username{
                    display: inline-block;
                    margin-left:10px;
                    max-width:450px;
                    white-space: nowrap;
                    word-break: break-word;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    vertical-align:top; 
                }
                .on-list{
                    font-size:15px;
                    font-weight:bold; 
                }
            }
        }
        .text-left{
            text-align:left; 
        }
        .rank{
            font-weight:bold;
            color:${props=>{
                return Config.colour
            }};
            font-size:15px;
        }
    }
`
class RankList extends Component{
    constructor(props){
        super(props)
        this.state={

        }
    }
    componentDidMount() {
        
    }
    componentWillUnmount = () => {
        this.setState=(state,callback)=>{
            return
        }
    };
    render(){
        const data=this.props.data;
        return <Div>
            <table>
                <thead>
                    <tr>
                        <th style={{width:"20%"}}>排名</th>
                        <th style={{width:"50%"}} className="text-left">昵称</th>
                        <th style={{width:"30%"}} className="text-left">累计积分</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        data !== undefined && data.length>0&&
                        data.map((val,k)=>{
                            return <tr key={"key"+k}>
                                <td>
                                    {k+1===1?<img src={First} alt=""/>:k+1===2?<img src={Second} alt=""/>:k+1===3?<img src={Third} alt=""/>:k+1}
                                </td>
                                <td className="text-left">
                                    <Avatar src={val.photo} size={40} />
                                    <span className="username on-list">{val.username}</span>
                                </td>
                                <td className="text-left rank">{val.points}</td>
                            </tr>
                        })
                    }
                    {
                        data !== undefined && data.length ===0 &&
                        <tr><td colSpan="3">
                            暂无数据
                        </td></tr>
                    }
                </tbody>
            </table>
        </Div>
    }
}
export default RankList
