import React, { Component } from 'react';
import styled,{css} from 'styled-components';
import cha from '../images/form-close.png';
const outer = css `${props => props.css}`;
const Inputs = styled.div`
    width:${props=>props.w?props.w:"180px"};
    height:${props=>props.h?props.h:"37px"};
    font-size: 16px;
    position:relative;
    margin-bottom:3px;
    display: inline-block;
    ${outer}
    input{
        width:calc(100% - 20px);
        height:100%;
        border:1px solid ${props=>props.bdColor?props.bdColor:"#7059ef"};
        padding-left:10px;
        padding-right:30px;
        background:transparent;
        font-size: 14px;
        color:#333;
        box-sizing:border-box;
    }
    img{
        width:15px;
        height:15px;
        position:absolute;
        right:30px;
        top:50%;
        margin-top:-7.5px;
        cursor:pointer;
    }
`;
class Input extends Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            value: props.defaultValue||""
        }
    }
    handleChange(event) {
        // this.name = event.target.name;
        let value = event.target.value.replace(/(^\s*)|(\s*$)/g, "");
        if(this.props.pattern){
           value = event.target.value.replace(this.props.pattern, "");
        }
        this.setState({
            value: value
        });
        this.props.changeInput ? this.props.changeInput(value,this.props.name):this.cancel()
    }
    handleClick(){
        this.setState({
            value: ""
        });
        this.props.changeInput ? this.props.changeInput("", this.props.name) : this.cancel()
    }
    inputFocus(){
        this.props.focusInput(this.props.name)
    }
    cancel(){

    }
    render() {
        return (
            <Inputs
              w={this.props.width}
              h={this.props.height}
              css={this.props.style?this.props.style:``}
              bdColor={this.props.bdColor}
            >
                <input 
                    type={this.props.type} 
                    name={this.props.name} 
                    maxLength={this.props.maxLength}
                    placeholder={this.props.placeholder?this.props.placeholder:""}
                    value={this.props.defaultValue || this.state.value}
                    onChange={this.handleChange.bind(this)}
                    onFocus={this.inputFocus.bind(this)}
                    min={(this.props.min||this.props.min===0)?this.props.min:""}
                    title={this.props.title?this.props.title:""}
                    />
                {
                    ((this.props.defaultValue && this.props.defaultValue!=="") || this.state.value !== "") &&
                    <img onClick={this.handleClick.bind(this)} src={cha} alt="cha"/>
                }
                
            </Inputs>
        );
    }
}
export default Input;