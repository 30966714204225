import React, { Component } from 'react';
import styled from 'styled-components';
import Bg from '../../images/home_bg.png';
import $ from 'jquery';
import Activity from './Activity';
import Config from '../../json/config.json';
import HighEvents from './HighEvents';
import PublicTest from '../../images/public_test.png';
import Dynamic from './Dynamic';
import ServiceProcess from '../../images/service_process.png';
import Partner from './Partner';
import { Alert } from '../toThousands';
import Fetch from '../Fetch';

const Div=styled.div`
    .home-header{
        // width: 100%;
        background:${props=>{
            return "url("+Bg+") no-repeat"
        }};
        position: relative;
        .short-login{
            top:100px;
            position:absolute;
            right:50px;
            input{
                padding:0 10px;
                height: 30px;
            }
            button{
                margin-left:20px;
                height: 30px;
                width: 60px;
                cursor: pointer;
                color:#fff;
                background: ${props => {
                    return Config.colour
                }}
            }
        }
        background-size: 100% 100%;
        .home-header-content{
            padding-top:5%;
            width: 1200px;
            margin:0 auto;
            color:#fff;
            .title{
                font-size:65px;
                line-height:100px; 
                font-weight:bold; 
            }
            .homeCon{
                width: 60%;
                line-height: 42px;
                font-size: 16px;
                letter-spacing: 1px;
                margin-bottom: 30px;
            }
            a{
                display: inline-block;
                color:#fff;
                font-size:20px;
                border:1px solid #fff;
                padding:5px 10px;
                margin-top:10px;
                border-radius:5px;
            }
        }
    }
    .recommend{
        width: 1200px;
        margin:0 auto;
    }
    .high-demand-events{
        width: 1200px;
        margin:0 auto;
        margin-top:50px;
        margin-bottom:20px;
    }
    .high-demand-events-title{
       padding-bottom:50px;
        text-align:center; 
        .name{
            color:#394782;
            font-size:30px;
            font-weight:bold; 
            line-height:60px; 
        }
        .line{
            position: relative;
            width: 330px;
            margin:0 auto;
            border-top:${props=>{
                return "1px solid "+Config.colour
            }}
            .english-name{
                position: absolute;
                letter-spacing:5px; 
                color:#999999;
                background:#fff;
                padding: 0 20px;
                font-size: 20px;
                bottom: -13px;
                left: 82px;
            }
        }  
    }
    .dynamic-state{
        padding-top:2%;
        background:${props=>{
            return "url("+PublicTest+") no-repeat"
        }};
        background-size: 100% 100%;
    }
    .flow{
        width: 1200px;
        margin:50px auto;
        text-align:center; 
        p{
            font-size:30px;
            color:${props=>{
                return Config.colour
            }};
            font-weight:bold;
            line-height:70px;  
        }
        div{
            a{
                display: inline-block;
                color:#fff;
                background-color:${props=>{
                    return Config.colour
                }}; 
                font-size:18px;
                padding:5px 20px;
                cursor: pointer;
            }
        }
    }
    .partner{
        width: 1200px;
        margin:0 auto;
    }
`
class Home extends Component{
    constructor(props){
        super(props)
        this.state={
            username:""
        }
    }
    componentDidMount() {
        $(".home-header").css({
            "height": $("body").width()>1200?$("body").width()*0.47:1200*0.47
        })
        $(".dynamic-state").css({
            "height": $("body").width()>1200?$("body").width()*0.26:1200*0.26
        })
        $("body").css({
            background:"#fff"
        })
    }
    componentWillUnmount = () => {
        $("body").css({
            background:"#F1F0F0"
        })
        this.setState=(state,callback)=>{
            return
        }
    };
    handleChange(event){
        var value=event.target.value;
        this.setState({
            username:value
        })
    }
    handleLogin(){
        let username=this.state.username;
        Fetch.post("/base/login/",{
            body: JSON.stringify({username:username}),
            headers: {
                "Content-Type": "application/json;charset=UTF-8"
            }}).then(data=>{
                if(data.status===1){
                    Alert("success","登录成功")
                }else{
                    Alert("error",data.info)
                }
            })
    }
    handleLogout(){
        Fetch.get("/base/logout/").then(data=>{
            Alert("success","退出成功")
        })
    }
    render(){
        return <Div>
            {/* 首页顶部 */}
            <div className="home-header">
                <div className="home-header-content">
                    <div className="title">雁行欲行，行岂无凭</div>
                    <div className="homeCon">雁行众测致力于帮助企业用户以竞测的方式解决安全漏洞。雁行众测连接bugScan社区1000+核心认证白帽子，采取漏洞悬赏的新模式让企业获得最大收益，全程流量监控与审计让测试过程更安全可靠，专业漏洞报告和复测服务让企业能够更快定位漏洞并有效修复。</div>
                    <a href="/#/apply/testin" target="_blank" rel="noopener noreferrer">申请众测</a>
                </div>
                {/* 临时登录，退出 */}
                {
                    Config.Temporary_login&&
                    <div className="short-login">
                        <h1 style={{color:"#fff",fontSize:"25px"}}><b style={{color:"red",fontSize:"50px"}}>*</b>临时登录入口</h1>
                        <input type="text" value={this.state.username} onChange={this.handleChange.bind(this)}/>
                        <button onClick={this.handleLogin.bind(this)}>登录</button>
                        <button onClick={this.handleLogout.bind(this)}>退出</button>
                    </div>
                }
            </div>
            {/* 推荐活动 */}
            <div className="recommend">
                <Activity />
            </div>
            {/* 热门项目 */}
            <div className="high-demand-events">
                <div className="high-demand-events-title">
                     <div className="name">热门项目</div>
                     <div className="line">
                        <div className="english-name">PROJECT</div>
                     </div>
                </div>
                <HighEvents />
            </div>
            {/* 众测动态 */}
            <div className="dynamic-state">
                <div className="high-demand-events-title">
                     <div className="name">众测动态</div>
                     <div className="line">
                        <div className="english-name">DYNAMIC</div>
                     </div>
                </div>
                <Dynamic />
            </div>
            {/* 服务流程 */}
            <div className="flow">
                <div className="ServiceProcess">
                    <img src={ServiceProcess} alt=""/>
                </div>
                <p>服务流程</p>
                <div className="send-public">
                    <a href="/#/apply/testin" target="_blank" rel="noopener noreferrer">发起众测＞＞</a>
                </div>
            </div>
            {/* 合作伙伴 */}
            <div className="partner">
                <div className="high-demand-events-title">
                     <div className="name">合作伙伴</div>
                     <div className="line">
                        <div className="english-name">PARTNER</div>
                     </div>
                </div>
                <Partner />
            </div>
        </Div>
    }
}
export default Home
