import React, { Component } from 'react';
import styled from 'styled-components';
import Config from '../../json/config.json';
const Div=styled.div`
    width: 1200px;
    margin:20px auto;
    // margin-top:20px;
    position: relative;
    .catalog{
        background: #fff;
        width: 200px;
        display: inline-block;
        vertical-align:top; 
        // position: fixed;
        // top:0px;
        // left:0px;
        span{
            display: inline-block;
            width: 100%;
            line-height:50px; 
            font-size:16px;
            padding:0 20px;
            cursor: pointer;
            box-sizing:border-box;
            :hover{
                color:${props=>{
                    return Config.colour
                }}
            }
        }
        .active{
            color:${props=>{
                return Config.colour
            }}
        }
    }
    .description{
        background: #fff;
        width: 980px;
        display: inline-block;
        margin-left:20px;
        // margin-left: 220px;
        padding: 20px 30px;
        box-sizing:border-box;
        .title{
            font-size:22px;
            font-weight:bold;
            line-height:50px;  
        }
        .help-article__title{
            padding-bottom: 20px;
            color: rgba(37,37,50,.8);
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            text-align: center;
            border-bottom: 1px dashed #cfcfcf;
        }
        .content{
            font-size:15px;
            line-height:30px; 
            h3{
                font-size:16px;
            }
        }
    }
`
class HelpCenter extends Component{
    constructor(props){
        super(props)
        this.state={
            ative_model:1,
        }
    }
    componentDidMount() {
        
    }
    componentWillUnmount = () => {
        this.setState=(state,callback)=>{
            return
        }
    };
    handelAnchor(type){
        let anchor={
            1:"manufacturer",
            2:"flow",
            3:"submit",
            4:"award",
            5:"grade",
            6:"meltdown"
        }[type]
        this.setState({
            ative_model:type
        })
        let anchorElement = document.getElementById(anchor);
        anchorElement.scrollIntoView(); 
    }
    render(){
        const type=this.state.ative_model;
        return <Div>
            <div className="catalog">
                <span onClick={this.handelAnchor.bind(this,1)} className={type===1?"active":""}>厂商相关</span>
                <span onClick={this.handelAnchor.bind(this,2)} className={type===2?"active":""}>项目流程</span>
                <span onClick={this.handelAnchor.bind(this,3)} className={type===3?"active":""}>漏洞提交</span>
                <span onClick={this.handelAnchor.bind(this,4)} className={type===4?"active":""}>漏洞奖励</span>
                <span onClick={this.handelAnchor.bind(this,5)} className={type===5?"active":""}>漏洞评级</span>
                <span onClick={this.handelAnchor.bind(this,6)} className={type===6?"active":""}>关于【云演】回炉计划</span>
            </div>
            <div className="description">
                <div className="help-article__title">帮助中心</div>
                <div className="title" id="manufacturer">厂商相关</div>
                <div className="content">
                    为何选择入雁行众测平台？ 传统安全服务产品主要是用于自动化检测安全问题，有一定的局限性。而在雁行众测平台，全球白帽子和安全工程师参与测试，并利用攻击者思维，可以从更全面的角度帮助厂商发现安全问题，有效加强相应的漏洞预警和应急响应机制，从而提升业务修通的安全性。
                    怎样入驻厂商相关？<br/>
                    第一步，提交众测申请，留言需求；<br/>
                    第二步，平台收到信息，第一时间联系您。<br/>
                    第三步，沟通商量项目需求、项目规则、项目细节以及项目的评分机制。<br/>
                    第三步，白帽子挖掘漏洞，平台运营人员审核与厂商沟通漏洞定级与复现，并进行漏洞定级发放赏金和奖励。<br/>
                    需要注册雁行众测平台吗？如果只是想申请众测项目可不用注册，雁行众测是四叶草安全大社区旗下的一个子平台，平台提供云演：安全学习、漏洞演练、CTF竞赛、云演情报一条龙的服务，可根据实际需求决定是否注册。
                </div>
                <div className="title" id="flow">项目流程</div>
                <div className="content">
                众测项目需要白帽子实名认证信息，提交身份证号码、身份证正面、身份证反面以及真实姓名等信息，雁行众测平台承诺保护大家的隐私，实名认证的目的在于避免不必要的安全风险，目的只在于规范大家的渗透测试和和规避安全风险，希望白帽子理解。<br/>
                雁行众测平台是四叶草安全旗下大社区的一个子平台，使用统一身份证系统登录，登录后普通白帽子需要在【云演】平台完成学习和回炉锻造才能获得参与项目的资格，每个项目参与的资格根据项目来定，您是白帽子和在查看项目详情时提示。<br/>
                如果您是我们高级白帽子，则您可以直接参加该等级的项目，如果您不能参加该项目可点击申请，平台运营人员知晓后会评估，决定您是否能参加该项目。<br/>
                您有权限看到项目详情时表示您已经获得参与项目的资格，您先漏洞后可点击提交漏洞，提交漏洞名称，漏洞建议等级、分类、漏洞描述、漏洞复验过程，漏洞修复建议等信息。平台运营人员会在第一时间进行确认和审核。审核的状态在您的个人中心【我的漏洞】可查看漏洞审核的状态和审核意见。
                </div>
                <div className="title" id="submit">漏洞提交</div>
                <div className="content">
                漏洞提交要求漏洞标题、漏洞类型、漏洞等级、漏洞描述和复验步骤以及修复方案，漏洞描述为文本描述漏洞信息，复验步骤为该漏洞的详细验证过程，包括漏洞点，漏洞攻击结果，其中的漏洞验证过程等，越详细确认过程越快，避免浪费您的时间！
                </div>
                <div className="title" id="award">漏洞奖励</div>
                <div className="content">
                关于项目的漏洞奖励，根据每个项目的奖金池来定，先到先得，奖金池分配完毕后，平台方可向厂商申请奖励，但不保证一定能申请到追加的奖励<br/>
                根据漏洞的等级（严重、高危、中危、低危）每个项目的漏洞赏金均不一样，提交漏洞时还有额外的积分，积分可作为特邀项目或者四叶草安全旗下其他的活动的特邀嘉宾的凭证。也是作为白帽子入住平台身份的象征<br/>
                关于奖励兑换，您可以直接在商城兑换对应的赏金，直接构面商城的相关商品，我们会第一时间为你寄送
                </div>
                <div className="title" id="grade">漏洞评级</div>
                <div className="content">
                    雁行众测漏洞等级评定标准依据为CVSS v3(Common Vulnerability Scoring System，即“通用漏洞评分系统”)，根据漏洞对系统机密性（C）、完整性(I)、可用性(A)影响，将漏洞等级分为【严重】、【高危】、【中危】、【低危】四个等级。<br/>
                    <h3>严重</h3>

                    1.直接获取核心服务器权限的漏洞，包括但不限于上传Webshell、任意代码执行、远程命令执行等；<br/>

                    2.核心系统严重的信息泄露漏洞，包括但不限于核心DB的SQL注入、大量用户严重敏感身份信息泄露（至少包含3个敏感字段：姓名/身份证、银行卡信息、手机号/邮箱、密码、地址）、企业内部核心数据泄露等；<br/>

                    3.核心系统严重的逻辑设计或流程缺陷，包括但不限于批量修改任意账号密码漏洞、任意账号资金消费、任意金额修改的支付漏洞等；<br/>

                    4.严重影响核心系统可用性的漏洞，如可直接导致核心系统业务瘫痪的拒绝服务漏洞（如S2-020补丁绕过切换web目录可直接导致网站挂掉）；<br/>

                    <h3>高危</h3>

                    1.直接获取重要业务服务器权限的漏洞。包括但不限于任意命令执 行、上传webshell、任意代码执行；<br/>

                    2.直接导致重要的信息泄漏漏洞。包括但不限于重要DB的SQL注入漏洞；重要业务大量源码泄露；<br/>

                    3.严重的越权访问。包括但不限于绕过认证访问重要系统后台；批量盗取用户重要身份信息；<br/>

                    4.较严重的逻辑设计或流程缺陷，包括但不限于重要系统任意密码重置漏洞；<br/>

                    <h3>中危</h3>

                    1.需交互才能获取用户身份信息的漏洞。包括但不限于核心及重要系统存储型XSS漏洞；<br/>

                    2.任意文操作漏洞。包括但不限于任意文件读、写、删除、下载等操作；<br/>

                    3.普通的越权访问。包括但不限于绕过限制修改用户资料、执行用户操作、读取用户信息；绕过限制访问非重要系统后台；<br/>

                    4.比较严重的信息泄漏漏洞。包括但不限于敏感信息文件泄露（如DB连接密码）；<br/>

                    <h3>低危</h3>

                    1.反射型XSS；非重要系统存储型XSS；<br/>

                    2.敏感操作的CSRF；URL跳转；危害有限的越权操作（如越权清空/添加购物车等）；<br/>

                    3.轻微信息泄漏。包括但不限于phpinfo；非核心系统的svn信息泄露；非重要信息的泄露（如不能利用的DB连接密码等）；客户端应用本地SQL注入；<br/>

                    4.难以利用但存在安全隐患的漏洞。包括但不仅限于难以利用的SQL注入点；客户端本地拒绝服务；<br/>

                    其他不在细分类型里的问题，根据实际危害及CVSS进行综合评价。<br/>
                </div>
                <div className="title" id="meltdown">关于【云演】回炉计划</div>
                <div className="content">
                    关于【云演】回炉计划是为了您能挖出更NB的漏洞和提交更有价值的众测漏洞，默认注册，需要先到【云演】完成项目要求的学习成就。
                </div>
            </div>
        </Div>
    }
}
export default HelpCenter
