import React,{Component}from 'react';
import '../css/App.css';
import {
  Router,
  Route,
  Switch,
} from 'react-router-dom';
import history from './history';
import Home from './Home/Home';
import Header from './Home/Header';
import Footer from './Home/Footer';
import ProjextHall from './ProjectHall/ProjectHall';
import WorlHeroes from './WorlHeroes/WorlHeroes';
import HelpCenter from './HelpCenter/HelpCenter';
import Notice from './Notice/Notice';
import 'swiper/dist/css/swiper.min.css';
import ApplyTestin from './Home/ApplyTestin';
import Personal from './PersonalCenter/Personal';
import '../css/font-awesome.min.css';
import {connect} from "react-redux";
import {setShow} from './redux/Actions';
import store from './redux/Store';
class App extends Component {
    constructor(props){
        super(props);
        this.state={
            headerShow:true
        }
    }
    refresh() {
        if (history.location.pathname.indexOf("/apply/testin") !== -1) {
            store.dispatch(setShow(false));
        } else {
            store.dispatch(setShow(true));
        }
    }
    bind_uri_refresh() {
        window.addEventListener('load', this.refresh.bind(this), false);
        window.addEventListener('hashchange', this.refresh.bind(this), false);
    }
    componentDidMount(){
        this.bind_uri_refresh();
    }
    componentWillUnmount(){
        this.setState = (state,callback)=>{
            return;
        };
    }
    render(){
        const headerShow=this.props.isShow;
        return (
            <Router history={history}>
                <div className="App">
                    {
                        headerShow&&
                        <Header />
                    }
                    <div className="app-content">
                        <Switch>
                            {/* 首页 */}
                            <Route exact path="/" component={(param)=>{
                                return <Home />
                            }}/>
                            {/* 项目大厅 */}
                            <Route path="/projexthall" component={(param)=>{
                                return <ProjextHall />
                            }}/>
                            {/* 英雄榜 */}
                            <Route path="/heroes" component={(param)=>{
                                return <WorlHeroes />
                            }}/>
                            {/* 帮助中心 */}
                            <Route path="/help" component={(param)=>{
                                return <HelpCenter />
                            }}/>
                            {/* 公告 */}
                            <Route path="/notice" component={(param)=>{
                                return <Notice />
                            }}/>
                            {/* 申请众测 */}
                            <Route path="/apply/testin" component={(param)=>{
                                return <ApplyTestin />
                            }}/>
                            {/* 个人中心 */}
                            <Route path="/userInfo" component={(param)=>{
                                return <Personal />
                            }}/>
                        </Switch>
                    </div>
                    {
                        headerShow&&
                        <Footer />
                    }
                </div>
            </Router>
          );
    }
}
const mapStateToProps = (state)=> {
    return {
       isShow:state.show
    }
};
export default connect(
   mapStateToProps,
)(App);
