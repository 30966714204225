import React, { Component } from 'react';
import styled from 'styled-components';
import {toThousands} from '../toThousands';
import Config from '../../json/config.json'; 
import history from '../history';

const Div=styled.div`
    .detail-header{
        background:#fff;
        min-height:100px;
        box-sizing:border-box;
        border-radius:5px;
        margin-top:20px;
        .project-img{
            display: inline-block;
            vertical-align:top; 
            width: 400px;
            img{
                width: 100%;
                cursor:pointer;
            }
        }
        .project-referral{
            display: inline-block;
            vertical-align:top; 
            width: 800px;
            box-sizing:border-box;
            padding:10px 20px;
            .project-name{
                font-size:20px;
                color:#000; 
                line-height:50px; 
                word-break: break-word;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                width: 100%;
                font-weight:bold; 
                cursor:pointer;
            }
            .project-bonus{
                color:#FF4747;
                font-size:14px;
                line-height: 25px;
            }
            .project-time{
                font-size:14px;
                line-height: 20px; 
                .submit-vuln{
                    float: right;
                    display: inline-block;
                    background:${props=>{
                        return Config.colour
                    }};
                    color:#fff;
                    padding:5px 15px;
                    cursor: pointer;
                    border-radius:3px;
                }
            } 
            .project-intro>pre{
                font-size:13px;
                line-height: 20px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                word-break: break-all;
                white-space: pre-wrap;
            }
        }
    }
`
class DetailHeader extends Component{
    constructor(props){
        super(props);
        this.state={

        }
    }
    componentDidMount(){

    }
    componentWillUnmount(){
        this.setState=(state,callback)=>{
            return
        }
    }
    handleReturn(){
        history.push("/projexthall/detail/" + this.props.projectId)
    }
    render(){
        const detail=this.props.detail?this.props.detail:{};
        return <Div>
            <div className="detail-header">
                <div className="project-img">
                    <img onClick={this.handleReturn.bind(this)} src={detail.example_photo} alt=""/>
                </div>
                <div className="project-referral">
                    <div className="project-name" onClick={this.handleReturn.bind(this)} title={detail.name}>
                        {detail.name}
                    </div>
                    <div className="project-intro" title={detail.brief}>
                        <pre>
                            {detail.brief}
                        </pre>
                    </div>
                    <div className="project-bonus">奖金池：{toThousands(detail.bouns_pool)}</div>
                    <div className="project-time">
                        期限：{detail.start_time} - {detail.end_time}
                        {
                            detail.status===2&&this.props.showSubmit&&
                            <span className="submit-vuln" onClick={this.props.submit}>提交漏洞</span>
                        }
                    </div>
                </div>
            </div>
        </Div>
    }
}
export default DetailHeader