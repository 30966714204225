import React, { Component } from 'react';
import styled from 'styled-components';
import Close from '../../images/close.png';
import Front from '../../images/front.png';
import Reverse from '../../images/reverse.png';
import Config from '../../json/config.json';
import $ from 'jquery';
import PubSub from 'pubsub-js';
import { Alert } from '../toThousands';

const Div=styled.div`
    position: fixed;
    top:0;
    left:0;
    bottom:0;
    right:0;
    background: rgba(0,0,0,.5);
    display: flex;
	flex-flow:row wrap;
	justify-content:center;
    align-items:center;
    z-index:10;
    .real-name-verify{
        width: 750px;
        // height: 630px;
        background:#fff;
        border-radius:5px;
        .close-box{
            text-align:right;
            padding-top:20px; 
            padding-right:20px; 
            img{
                cursor: pointer;
            }
        }
        .real-name-verify-header{
            padding:0 40px;
            div{
                font-size:25px;
                font-weight:bold; 
                line-height:35px; 
            }
            span{
                color:#999;
            }
        }
    }
    .upload-card{
        padding: 20px 40px 40px 40px;
    }
    .upload-card>.upload-card-title{
        line-height:40px;
        font-size:16px; 
    }
    .upload-card>.single-upload{
        display: inline-block;
        border-radius:4px;
        width: 325px;
        height: 180px;
        text-align:center; 
        box-shadow: 0px 6px 16px 0px rgba(209, 209, 209, 0.35);
        vertical-align: top;
    }
    .upload-card>.single-upload>.idcardBox{
        display: inline-block;
        width: 131px;
        height: 98px;
        margin: 20px 0;
        cursor: pointer;
    }
    .upload-card>.single-upload>.idcardBox>img{
        width: 131px;
        height: 98px;
    }
    .upload-card>.single-upload span{
        color:#ff8f88;
    }
    .real-message{
        .color-bg{
            height: 20px;
            background:#f8f8f8;
        }
        .fill-info{
            line-height:50px;
            padding:0 40px;
            span{
                display: inline-block;
                width: 80px;
            } 
            input{
                width: 580px;
                margin-left:10px;
            }
        }
    }
    .submit-message{
        text-align:center; 
        margin-top:30px;
        p{
            color:#999;
            margin-bottom:10px; 
        }
        button{
            color:#fff;
            background:${props=>{
                return Config.colour
            }}
            font-size:16px;
            font-weight:bold;
            padding: 5px 40px;
            cursor: pointer;
            border-radius:3px;
            margin-bottom: 20px;
        }
    }
    .errorPhotoLeft{
        text-align: left;
        margin-top: 28px;
    }
`
class RealVerify extends Component{
    constructor(props){
        super(props)
        this.state={
            frontCard:Front,
            reverseCard:Reverse,
            // 错误信息
            realname:"",
            id_card:"",
            card_back_photo:"",
            card_front_photo:"",
        }
    }
    componentDidMount() {
        document.body.style.overflow="hidden";
    }
    componentWillUnmount = () => {
        this.setState=(state,callback)=>{
            return
        }
        document.body.style.overflow="unset";
    };
    //点击上传人面像
    handleReverseUpload(){
        document.getElementById("reverseCard").click()
    }
    //点击上传国徽面
    handleFrontUpload(){
        document.getElementById("frontCard").click()
    }
    //change上传人面像
    handleReverseChange(){
        let file = document.getElementById("reverseCard").files[0];
        var url=Reverse;
        if(file!==undefined){
            if (window.createObjectURL !== undefined) { // basic
                    url=window.createObjectURL(file)
            } else if (window.URL !== undefined) { // mozilla(firefox)
                url=window.URL.createObjectURL(file)
            } else if (window.webkitURL !== undefined) { // webkit or chrome
                url=window.webkitURL.createObjectURL(file)
            }
        }
        this.setState({
            reverseCard:url,
            card_front_photo: ""
        })
    }
    //change上传国徽面
    handleFrontChange(){
        let file = document.getElementById("frontCard").files[0];
        var url=Front;
        if(file!==undefined){
            if (window.createObjectURL !== undefined) { // basic
                    url=window.createObjectURL(file)
            } else if (window.URL !== undefined) { // mozilla(firefox)
                url=window.URL.createObjectURL(file)
            } else if (window.webkitURL !== undefined) { // webkit or chrome
                url=window.webkitURL.createObjectURL(file)
            }
        }
        this.setState({
            frontCard:url,
            card_back_photo: ""
        })
    }
    handleSubmit() {
        // 上传文件必须使用ajax
        var form = document.getElementById('authentication');
        let formData = new FormData(form);
        var that = this;
        $.ajax({
            url: "/base/user/submit/",
            type: "PATCH",
            data: formData,
            contentType: false,
            processData: false,
            success: function (res) {
                that.props.close()
                PubSub.publish('RenZheng', true);  
            },
            error: function (err) {
                if (err.responseJSON.detail) {
                    // 提示信息
                    Alert("error", err.responseJSON.detail)
                }
                if (err.responseJSON.realname) {
                    that.setState({
                        realname: err.responseJSON.realname
                    })
                }
                if (err.responseJSON.id_card) {
                    that.setState({
                        id_card: err.responseJSON.id_card
                    })
                }
                if (err.responseJSON.card_back_photo) {
                    that.setState({
                        card_back_photo: err.responseJSON.card_back_photo
                    })
                }
                if (err.responseJSON.card_front_photo) {
                    that.setState({
                        card_front_photo: err.responseJSON.card_front_photo
                    })
                }
            }
        });
    }
    handleFocusName(){
        this.setState({
            realname:""
        })
    }
    handleFocusId(){
        this.setState({
            id_card:""
        })
    }
    render(){
        const frontCard=this.state.frontCard;
        const reverseCard=this.state.reverseCard;
        return <Div>
            <div className="real-name-verify">
                <div className="close-box">
                    <img onClick={this.props.close} src={Close} alt=""/>
                </div>
                <div className="real-name-verify-header">
                    <div>实名认证</div>
                    <span>根据《网络安全法》要求，需要进行实名认证，身份证信息我们会严格保密</span>
                </div>
                <form className="authentication-info" id="authentication">
                    <div className="upload-card">
                        <div className="upload-card-title">身份证照片</div>
                        <div className="single-upload" style={{marginRight:"20px"}}>
                            <div className="idcardBox" onClick={this.handleReverseUpload.bind(this)}>
                                <img src={reverseCard} alt=""/>
                                <input name="card_front_photo" type="file" id="reverseCard" style={{display:"none"}} 
                                onChange={this.handleReverseChange.bind(this)}/>
                            </div>
                            <p>点击上传 <span>人面像</span></p>
                            <div className="error errorPhotoLeft">{this.state.card_front_photo}</div>
                        </div>
                        <div className="single-upload">
                            <div className="idcardBox" onClick={this.handleFrontUpload.bind(this)}>
                                <img src={frontCard} alt=""/>
                                <input name="card_back_photo" type="file" id="frontCard" style={{display:"none"}} 
                                onChange={this.handleFrontChange.bind(this)}/>
                            </div>
                            <p>点击上传 <span>国徽面</span></p>
                            <div className="error errorPhotoLeft">{this.state.card_back_photo}</div>
                        </div>
                    </div>
                    <div className="real-message">
                        <div className="color-bg"></div>
                        <div className="fill-info" style={{borderBottom:"1px solid #f8f8f8"}}>
                            <span>真实姓名</span>
                            <input name="realname" type="text" placeholder="请输入您的姓名"
                                onFocus={this.handleFocusName.bind(this)}/>
                            <div className="error">{this.state.realname}</div>
                        </div>
                        <div className="fill-info">
                            <span>身份证号码</span>
                            <input name="id_card" type="text" placeholder="请输入您的身份证号码"
                                onFocus={this.handleFocusId.bind(this)}/>
                            <div className="error">{this.state.id_card}</div>
                        </div>
                        <div className="color-bg"></div>
                    </div>
                </form>
                <div className="submit-message">
                    <p>身份证信息提交后我们会尽快审核，请关注您的个人信息查看</p>
                    <button onClick={this.handleSubmit.bind(this)}>提交信息</button>
                </div>
            </div>
        </Div>
    }
}
export default RealVerify
