import React, { Component } from 'react';
import './page.css';
import { TweenMax, Linear } from 'gsap';
/** 
 * 关于Page 组件参数的说明
 * headEnd      要不要 首页尾页 
 * upDown       要不要 上一页下一页 
 * pageNumber   要不要 中间的页数列表 
 * allNumber    要不要 共多少页 
 * jumpNumber   要不要 跳到多少页
 * allPage      数据总条数
 * everyPage    每页显示多少条数据
 * onClick      点击上传当前页到父组件  在父组件需要编写一个函数接收传过来的数据
 * onChange     数据改变上传当前页到父组件 在父组件需要编写一个函数接收传过来的数据 这两个函数是同一个函数
*/
class Pagenum extends Component{
    constructor(props) {
        super(props);
        this.buttonClick = this.buttonClick.bind(this);
    }
    buttonClick(){
        this.props.onClick(this.props.pagenum);
    }
    render(){
        return(
            <button 
               className={this.props.pagenum === this.props.nowpage?'active':''} 
               onClick={this.buttonClick}>{this.props.pagenum}</button>
        )
    }
}
// 获取当前显示的5个页码
function getPageArr(num, howpage) {
    let start, end, newArr = [];
    if (howpage.whatpage <= howpage.pagenums) {
        start = 0; end = howpage.whatpage;
    } else {
        if (num <= howpage.step) {
            start = 0; end = start + howpage.pagenums;
                    }
        if (num > howpage.step) {
            start = num - howpage.step-1; end = num + howpage.step;
                    }
        if (num >= howpage.whatpage - howpage.step) {
            start = howpage.whatpage - howpage.pagenums; end = howpage.whatpage
        }
    }
    for (let i = start; i < end; i++) {
        newArr.push(i + 1);
    }
    return newArr;
}
function getInitDate(allpage, everypage, pagenum) {
    // 总共多少页
    const paged = Math.ceil(allpage / everypage);
    let pageArr = [];
    if (paged >= pagenum) {
        for (let i = 1; i <= pagenum; i++) {
            pageArr.push(i)
        }
    } else {
        for (let i = 1; i <= paged; i++) {
            pageArr.push(i)
        }
    }
    return [paged, pageArr];
}
class Page extends Component {
    constructor(props) {
        super(props);
        // 数据初始化处理
        const pagenum = 5;
        this.state={
            forever:{
                pagenums:5,
                step: (pagenum-1)/2,
                whatpage: getInitDate(props.allPage, props.everyPage, pagenum)[0],
            },
            nowpage:props.nowpage,
            totalPage: getInitDate(props.allPage, props.everyPage, pagenum)[1],
            controls:{
                headEnd: props.headEnd,
                upDown: props.upDown,
                pageNumber: props.pageNumber,
                allNumber: props.allNumber,
                nowNumber: props.nowNumber,
                jumpNumber: props.jumpNumber,
                selectNumber: props.selectNumber
            },
            everypage: props.everyPage
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }
    componentDidMount() {
        this.setState({
            totalPage:getPageArr(this.props.nowpage, this.state.forever)
        })
        document.addEventListener('click', this.onClicks.bind(this), false);
    }
    componentWillUnmount() {
        document.removeEventListener('click', this.onClicks.bind(this), false);
    }
    onClicks(ev) {
        let e = ev || window.event; //浏览器兼容性 
        let elem = e.target || e.srcElement;
        while (elem) { //循环判断至跟节点，防止点击的是div子元素
            if (elem.className === `everyxia`) {
                return;
            }
            elem = elem.parentNode;
        }
       TweenMax.to(`.e-xiala`, 0.1, {
           height: 0,
           opacity: 0,
           ease: Linear.easeOut
       })

    }
    // 测试用
    componentWillReceiveProps(prop){
        let pagenum = 5;
        this.setState({
            forever: {
                pagenums: 5,
                step: (pagenum - 1) / 2,
                whatpage: getInitDate(prop.allPage, prop.everyPage, pagenum)[0],
            },
            everypage: prop.everyPage,
            nowpage: prop.nowpage,
            totalPage: getPageArr(prop.nowpage, {
                pagenums: 5,
                step: (pagenum - 1) / 2,
                whatpage: getInitDate(prop.allPage, prop.everyPage, pagenum)[0],
            }),
        });
    }
    handleChange(event){
        // 判断输入框里只能输数字
        let value = String(event.target.value).replace(/[^\d]/g, '');
        if (Number(value) >= 1 && Number(value) <= this.state.forever.whatpage){
            this.setState({
                nowpage: Number(value)
            });
            this.setState({
                totalPage: getPageArr(Number(value), this.state.forever)
            });
            this.props.onChange(value, this.state.forever.whatpage);
        }else{
            this.setState({
                nowpage: ""
            });
        }
    }
    handleClick(num){
        this.setState({
            nowpage: num,
            totalPage:getPageArr(num, this.state.forever)
        });
        this.props.onClick(num, this.state.forever.whatpage);
    }
    everyClick(){
        let dom = document.getElementById("e-xiala").offsetHeight;
        if (dom === 2) {
            TweenMax.to(`.e-xiala`, 0.1, {
                height: 60,
                opacity: 1,
                ease: Linear.easeIn
            })
        } else {
            TweenMax.to(`.e-xiala`, 0.1, {
                height: 0,
                opacity: 0,
                ease: Linear.easeOut
            })
        }
    }
    clickevery(num){
        TweenMax.to(`.e-xiala`, 0.1, {
            height: 0,
            opacity: 0,
            ease: Linear.easeOut
        })
        this.setState({
            everypage:num
        })
        this.props.changeNum ? this.props.changeNum(num):this.cancel()
    }
    cancel(){

    }
    render() {
        let initpage =this.state.nowpage!==""?parseInt(this.state.nowpage):"";
        const nowpage = this.state.nowpage!==""?parseInt(this.state.nowpage):"";
        const totalPage = this.state.totalPage;
        const forever = this.state.forever;
        const controls = this.state.controls;
        return (
           <div>
            {
                forever.whatpage>1&&
                <div className="page">
                    {controls.allNumber && forever.whatpage>1 &&
                        <span className="allnum" style={{marginRight:"10px"}}>共 {forever.whatpage} 页</span>
                    }
                    {controls.nowNumber && forever.whatpage>1 &&
                        <span className="allnum">当前第 <span style={{color:"#666"}}>{this.state.nowpage}</span> 页</span>
                    }
                    {controls.headEnd && forever.whatpage>1 &&
                        <button 
                        className={nowpage <= 1 ? 'disabled' : ''} 
                        onClick={this.handleClick.bind(this, 1)}>首页</button>
                    }
                    {controls.upDown && forever.whatpage > 1 &&
                        <button 
                        className={nowpage <= 1 ? 'disabled' : ''} 
                        disabled={nowpage <= 1 ? true : false} 
                        onClick={this.handleClick.bind(this, initpage-1)}>
                        <i className="fa fa-angle-left"></i>
                        </button>
                    }
                    {controls.pageNumber &&
                        <span className="pagenum">
                            {(nowpage - forever.step) >= 3 && forever.whatpage > forever.pagenums+1 &&
                                <React.Fragment>
                                    <button onClick={this.handleClick.bind(this,1)}>1</button>
                                    <span className="etc">…</span>
                                </React.Fragment>
                            }
                            {
                                totalPage.map((val)=>{

                                    return <Pagenum
                                        key={"key"+ val}
                                        pagenum={val}
                                        nowpage={nowpage}
                                        onClick={this.handleClick}
                                    />
                                })
                            }
                            {nowpage < (forever.whatpage - forever.step) && forever.whatpage > forever.pagenums + 1 &&
                                <React.Fragment>
                                    <span className="etc">…</span>
                                    <button onClick={this.handleClick.bind(this, forever.whatpage)}>{forever.whatpage}</button>
                                </React.Fragment>
                            }
                        </span>
                    }
                    {controls.upDown && forever.whatpage > 1 &&
                        <button 
                        className={nowpage >= forever.whatpage ? "disabled" : ""} 
                        disabled={nowpage >= forever.whatpage ? true : false} onClick={this.handleClick.bind(this, initpage+1)}>
                        <i className="fa fa-angle-right"></i>
                        </button>
                    }
                    {controls.headEnd && forever.whatpage > 1 &&
                        <button 
                        className={nowpage >= forever.whatpage ? "disabled" : ""} 
                        onClick={this.handleClick.bind(this, forever.whatpage)}>尾页</button>
                    }
                    
                    {controls.jumpNumber && forever.whatpage > 1 &&
                        <span className="jumpnum">
                            跳到 <input type="text" name="page" value={nowpage} onChange={this.handleChange}/> 页
                        </span>
                    }
                    {
                        controls.selectNumber && this.props.allPage>0 &&
                        <span className="every">
                            每页最多显示
                            <span className="everyxia">
                                <sapn onClick={this.everyClick.bind(this)}>{this.state.everypage}  <i className="fa fa-caret-down"></i></sapn>
                                <div id="e-xiala" className="e-xiala"> 
                                    <span onClick={this.clickevery.bind(this,10)} className={this.state.everypage===10?"active":""}>10</span>
                                    <span onClick={this.clickevery.bind(this,20)} className={this.state.everypage===20?"active":""}>20</span>
                                    <span onClick={this.clickevery.bind(this,50)} className={this.state.everypage===50?"active":""}>50</span>
                                </div>
                            </span>
                            条
                        </span>
                    }
                </div>
                }
           </div>
        )
    }
}
export default Page;