import React, { Component } from 'react';
import styled from 'styled-components';
import testin from '../../images/testin.png';
import $ from 'jquery';
import Config from '../../json/config.json';
import {Alert} from '../toThousands';
import Fetch from '../Fetch';
import history from '../history';

const Div=styled.div.attrs({className:"apply-testin"})`
    background:#fff;
    .testin-left,.testin-right{
        display: inline-block;
        vertical-align:top;
        width: 50%;
        box-sizing:border-box; 
        height: 100%;
    }
    .testin-left{
        background:${props=>{
            return "url("+testin+") no-repeat";
        }};
        background-size: 100%;
    }
    .testin-right{
         .title{
             text-align:center;
             font-size:25px; 
             font-weight:bold; 
             line-height:10vw; 
         }
         .form-input{
             margin-bottom:3vw;
             position: relative;
             span{
                display: inline-block;
                font-size:16px;
                position: relative;
                padding-left:15px;
                width: 80px;
                vertical-align:top; 
                line-height:40px; 
                b{
                    color:#ff4747;
                    font-size:20px;
                    position: absolute;
                    top:2px;
                    left:0px;
                }
             }
             input{
                 border:2px solid #eceef2;
                 border-radius:5px;
                 margin-left:15px;
                 box-sizing:border-box;
                 height: 40px;
                 padding:0 15px;
                 width: 70%;
             }
             img{
                position: absolute;
                left: 70.4%;
                top: 2px;
                height: 36px;
                cursor: pointer;
                border-radius: 0 3px 3px 0;
             }
             textarea{
                border:2px solid #eceef2;
                border-radius:5px;
                margin-left:15px;
                box-sizing:border-box;
                padding:10px 15px;
                resize: none;
                color:#333;
                width: 70%;
                height: 100px;
                font-size:14px;
             }
             .input-error{
                 margin-left:110px;
                 font-size:13px;
                 line-height:30px; 
                 color:#ff4747
             }
         }
         .sub-button{
             text-align:center; 
             button{
                background: ${props=>{
                    return Config.colour
                }}
                color:#fff;
                font-size:16px;
                padding:10px 50px;
                cursor: pointer;
                border-radius:5px;
             }
         }
    }
`
class Testin extends Component{
    constructor(props){
        super(props)
        this.state={
            submit:{
                name:"",
                phone:"",
                describe:"",
                code:""
            },
            error:{
                name: [],
                phone: [],
                describe: [],
                code: []
            },
            codeImg: `/ptp/captcha/?t=${new Date().getTime()}`
        }
    }
    componentDidMount() {
        $(".testin-left").css({
            "min-height":$(window).height()
        })
        $(".testin-right").css({
            "min-height":$(window).height()
        })
    }
    componentWillUnmount = () => {
        this.setState=(state,callback)=>{
            return
        }
    };
    // 名称onChange事件
    handleName(event){
        let error = this.state.error;
        let submit=this.state.submit;
        var value = event.target.value.replace(/(^\s*)|(\s*$)/g, "");
        if(value.length===100){
            error.name = "名称最多可输100字"
        }else{
            error.name = ""
        }
        Object.assign(submit,{
            name:value
        })
        this.setState({
            submit:submit
        })
    }
    // 手机号onChange事件
    handlePhone(event){
        let submit=this.state.submit;
        var value = event.target.value.replace(/(^\s*)|(\s*$)/g, "");
        Object.assign(submit,{
            phone:value
        })
        this.setState({
            submit: submit
        })
    }
    // 描述onChange事件
    // 验证码onChange事件
    handleDes(event){
        let error = this.state.error;
        let submit=this.state.submit;
        var value = event.target.value.replace(/(^\s*)|(\s*$)/g, "");
        if(value.length===250){
            error.describe = "需求描述最多可输250字"
        }else{
            error.describe = ""
        }
        Object.assign(submit,{
            describe:value
        })
        this.setState({
            submit: submit,
            error: error
        })
    }
    // 验证码onChange事件
    handleCode(event){
        let submit=this.state.submit;
        var value = event.target.value.replace(/(^\s*)|(\s*$)/g, "");
        Object.assign(submit,{
            code:value
        })
        this.setState({
            submit: submit
        })
    }
    // 获得焦点时清空错误信息
    handleFocus(name) {
        let error = this.state.error;
        if (name === "name") {
            Object.assign(error, {
                name: []
            })
        }
        if (name === "phone") {
            Object.assign(error, {
                phone: []
            })
        }
        if (name === "describe") {
            Object.assign(error, {
                describe: []
            })
        }
        if (name === "code") {
            Object.assign(error, {
                code: []
            })
        }
        this.setState({
            error: error
        })
    }
    // 点击图形验证码
    handleClickCode(){
        this.setState({
            codeImg: `/ptp/captcha/?t=${new Date().getTime()}`
        })
    }
    // 提交众测
    handleSubmit(){
        let submit=this.state.submit;
        let error = this.state.error;
        let codeImg = this.state.codeImg;
        if(submit.name===""){
            error.name="请输入厂家名称"
            this.setState({
                error:error
            })
            return
        }
        if (submit.phone === "") {
            error.phone = "请输入手机号"
            this.setState({
                error: error
            })
            return
        }
        if (submit.describe === "") {
            error.describe = "请输入需求描述"
            this.setState({
                error: error
            })
            return
        }
        if (submit.code === "") {
            error.code = "请输入验证码"
            this.setState({
                error: error
            })
            return
        }
        var list={"name":submit.name,"phone":submit.phone,"describe":submit.describe,"code":submit.code,}
        Fetch.post("/ptp/apply/detect/",{
            body: JSON.stringify(list),
            headers: {
                "Content-Type": "application/json;charset=UTF-8"
            }
        }).then((data)=>{
            Alert("success", "提交成功")
            history.push("/")
        }).catch((err)=>{
            if(err.name){
                error.name = err.name
            }
            if (err.phone) {
                error.phone = err.phone
            }
            if (err.describe) {
                error.describe = err.describe
            }
            if (err.code) {
                error.code = err.code
                codeImg=`/ptp/captcha/?t=${new Date().getTime()}`
            }
            if (err.non_field_errors) {
                error.code = err.non_field_errors
                codeImg = `/ptp/captcha/?t=${new Date().getTime()}`
            }
            this.setState({
                error:error,
                codeImg: codeImg
            })
        })
    }
    render(){
        const submit=this.state.submit;
        const error = this.state.error;
        return <Div>
            <div className="testin-left"></div>
            <div className="testin-right">
                <div className="title">申请众测</div>
                <div className="form-input">
                    <span>厂商名称<b>*</b></span>
                    <input type="text" 
                        value={submit.name}
                        maxLength="100"
                        placeholder="请输入您的公司或企业名称"
                        onChange={this.handleName.bind(this)}
                        onFocus={this.handleFocus.bind(this,"name")}/>
                    <div className="input-error">{error.name}</div>
                </div>
                <div className="form-input">
                    <span>手机号<b>*</b></span>
                    <input type="text" 
                        maxLength="11"
                        value={submit.phone}
                        placeholder="请留下联系方式，以便我们和您取得联系"
                        onChange={this.handlePhone.bind(this)}
                        onFocus={this.handleFocus.bind(this,"phone")}/>
                    <div className="input-error">{error.phone}</div>
                </div>
                <div className="form-input">
                    <span>需求描述<b>*</b></span>
                    <textarea placeholder='请对您的需求描述，例如 "某网站安全测试"'
                        maxLength="250"
                        onChange={this.handleDes.bind(this)}
                        onFocus={this.handleFocus.bind(this,"describe")}></textarea>
                    <div className="input-error">{error.describe}</div>
                </div>
                <div className="form-input">
                    <span>验证码<b>*</b></span>
                    <input type="text" 
                        maxLength="4"
                        value={submit.code}
                        placeholder="请输入验证码"
                        onChange={this.handleCode.bind(this)}
                        onFocus={this.handleFocus.bind(this,"code")}/>
                    <img className="code" src={this.state.codeImg} alt=""
                        onClick={this.handleClickCode.bind(this)}/>
                    <div className="input-error">{error.code}</div>
                </div>
                <div className="sub-button">
                    <button onClick={this.handleSubmit.bind(this)}>提交申请</button>
                </div>
            </div>
        </Div>
    }
}
export default Testin
