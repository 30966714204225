import React, { Component } from 'react';
import styled, {css} from 'styled-components';
// import cha from '../image/cha.png';
import { TweenMax, Linear } from 'gsap';
import "../css/font-awesome.min.css";
import Config from '../json/config';

const outer=css`${props => props.css}`;
const Down = styled.div.attrs({
    id: props=>props.sign
})`
    width:${props=>props.w?props.w:"300px"};
    height:${props=>props.h?props.h+"px":"40px"};
    border-radius:${props=>props.h?props.h/2+"px":"20px"};
    border: 1px solid ${props=>props.bdColor?props.bdColor:"#7059ef"};
    font-size: 16px;
    position:relative;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    ${outer}
    span{
        display:block;
        height:100%;
        line-height:${props=>props.h?props.h+"px":"40px"};
        cursor:pointer;
    }
    .kuang{
        width: 100%;
        padding-left:10px;
        // padding-right:20px;
        word-break: break-word;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        // width:calc(100% - 60px);
        position: relative;
        font-size: 14px;
        img{
            width:15px;
            height:15px;
            cursor:pointer;
            position:absolute;
            right:0;
            top:50%;
            margin-top:-8px;
        }
    }
    .sanjiao{
        font-size:16px;
        text-align:center;
        width:30px;
        color: #7059ef;
        i{
            transform:rotate(-90deg)
        }
    }
    .xialas{
        width:100%;
        max-height:0;
        opacity:0;
        border:1px solid ${props => {
            return Config.colour
        }};
        position:absolute;
        left:0;
        // background: rgb(35, 39, 47);
        background: #fff;
        z-index:200;
        top:${props=>props.h?(props.h+2)+"px":"42px"};
        text-align: center;
        div{
            span{
                padding-left:10px;
                padding-right:10px;
                height:30px;
                line-height:30px;
                font-size:14px;
                color:#333;
                word-break: break-word;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                transition:all 0.2s ease;
                text-align: left;
                &:hover{
                    background: #7059ef;
                    color: #fff;
                }
            }
            .active{
                background: #7059ef;
                color: #fff;
            }
        }
    }
`;
class Select extends Component {
    constructor(props, context) {
        super(props, context);
        this.state={
            select: 0,
            default: props.default || "请选择",
            selectname: "",
            show:false
        };
        this.showDown = this.showDown.bind(this);
        this.hideDown = this.hideDown.bind(this);
        
    }
    componentWillReceiveProps(props){
        this.setState({
            default:props.default||"请选择"
        })
    }
    componentDidMount() {
        this._Mounter = true;
        document.addEventListener('click', this.onClicks.bind(this), false);
    }
    componentWillUnmount() {
        this.setState = (state,callback)=>{
            return;
        };
        this._Mounter = false;
        document.removeEventListener('click', this.onClicks.bind(this), false);
    }
    getDefault(list,value){
        let ops = list.find(val => {
            return val.id === value;
        })
        if (ops){
            return ops.name;
        }else{
            return ""
        }
        
    }
    onClicks(ev) {
        if (this._Mounter){
            var e = ev || window.event; //浏览器兼容性 
            var elem = e.target || e.srcElement;
            while (elem) { //循环判断至跟节点，防止点击的是div子元素
                if (elem.id === `select${this.props.sign?this.props.sign:"a"}`) {
                    return;
                }
                elem = elem.parentNode;
            }
            this.hideDown()    
        }
        
    }
    handleClick(e){
        e.stopPropagation();
        let show=this.state.show;
        if (!show){
            this.showDown()
        }else{ 
            this.hideDown()
        }
        this.props.selectFocus(this.props.name);
    }
    showDown(){
        this.setState({
            show: true
        }, () => {
            TweenMax.set(`.xiala${this.props.sign}`, {
                overflow: "hidden"
            })
            TweenMax.to(`.xiala${this.props.sign}`, 0.1, {
                maxHeight: 210,
                opacity: 1,
                ease: Linear.easeIn,
                onComplete: () => {
                    TweenMax.set(`.xiala${this.props.sign}`, {
                        overflow: "auto"
                    })
                }
            })
            // TweenMax.to(`.sanjiao${this.props.sign}`, 0.2, {
            //     rotation: 90,
            //     ease: Linear.easeIn
            // })
        })
    }
    hideDown(){
        TweenMax.set(`.xiala${this.props.sign}`, {
            overflow: "hidden"
        })
        TweenMax.to(`.xiala${this.props.sign}`, 0.1, {
            maxHeight: 0,
            opacity: 0,
            ease: Linear.easeOut,
            onComplete: () => {
                this.setState({
                    show: false
                });
            }
        })
        // TweenMax.to(`.sanjiao${this.props.sign}`, 0.2, {
        //     rotation: 0,
        //     ease: Linear.easeIn
        // })
    }
    empty(e){
        e.stopPropagation();
        this.setState({
            select:0,
            selectname: "",
        })
        this.hideDown();
        this.props.delSelect ? this.props.delSelect("",this.props.name,"") : this.cancel()
    }
    selectOne(value){
        this.setState({
            select: value.id,
            selectname: value.name,
        })
        this.hideDown();
        this.props.delSelect ? this.props.delSelect(value.id, this.props.name, value.name) : this.cancel()
    }
    cancel(){

    }
//      {
//     (value !== "" || (this.state.select !== 0 && !this.props.notempty)) &&
//         <img onClick={this.empty.bind(this)} title="清空" src={cha} alt="hol" />
// }
    render() {
        const list=this.props.list;
        const value = (this.props.value && this.props.value !== "") ? this.props.value:"";
        return (
            <Down 
                w={this.props.width} 
                h={this.props.height}
                bdColor={this.props.bdColor} 
                sign={`select${this.props.sign?this.props.sign:"a"}`}
                css={this.props.style?this.props.style:``}
            >
                <span className={`kuang`}  
                    title={value === "" ? this.state.selectname !== "" ? this.state.selectname : "":
                    this.getDefault(list, value)
                } onClick={this.handleClick.bind(this)}
                >

                    <span style={
                    //    value === "" ?
                    //    this.state.select !== 0?{color:"#fff"}:{color:"#5f6a81"}:
                       {color:"#333"}
                    }>
                    {
                        value===""?
                        this.state.selectname!==""?this.state.selectname:this.state.default:
                        this.getDefault(list,value)
                    }
                    </span>
                </span>
                <span className={`sanjiao sanjiao${this.props.sign?this.props.sign:"a"}`}
                onClick={this.handleClick.bind(this)}
                ><i className="fa fa-caret-left"></i></span>
                {this.state.show &&
                    <div className={`xialas xiala${this.props.sign?this.props.sign:"a"}`}>
                        {
                            list && list.length!==0 &&
                            list.map((val,k)=>{
                                return <div key={"key"+k}>
                                    <span
                                    title={val.name} 
                                    onClick={this.selectOne.bind(this,val)}
                                     className={
                                    this.state.select === val.id?"active":""
                                    }>{val.name}</span>
                                </div>
                            })
                        }
                    </div>
                }
                
            </Down>
        );
    }
}
export default Select;