import React, { Component } from 'react';
import styled from 'styled-components';
import DetailHeader from './DetailHeader';
import history from '../history';
import Zidui from '../../images/zidui.png'; 
import close from '../../images/close.png'; 
import Config from '../../json/config.json';
import Fetch from '../Fetch';
import Local from '../Local';
import {Alert} from '../toThousands';
import Input from "../Input";

const Div=styled.div`
    width: 1200px;
    margin:0 auto;
    .form-submit{
        margin-top:20px;
        background:#fff;
        border-radius:4px;
    }
    .form-submit>p{
        text-align:center;
        line-height: 40px;
        font-weight:bold;
        font-size:16px;
        border-bottom:1px solid #eee;
    }
    .form-submit-content{
        padding:20px 100px;
        .singleInput{
            margin-bottom: 15px;
            .titBox{
                position: relative;
                vertical-align: top;
                .red-star{
                    color: red;
                    top: -2px;
                }
            }
            .fuEditer{
                width: 900px;
                display: inline-block;
                vertical-align: top;
                margin-left: 20px;
            }
        }
        .xieyi{
            padding: 0 76px;
            margin-top: 20px;
            .checkBox{
                display: inline-block;
                width: 18px;
                height: 18px;
                border: 1px solid #666;
                vertical-align: middle;
                cursor: pointer;
                margin-top: -1px;
                img{
                    margin-top: -8px;
                }
            }
            .xieyiText{
                font-size: 13px;
                cursor: pointer;
                color: #7296FA;
            }
        }
        .btnBox{
            padding: 0 76px;
            margin-top: 10px;
            margin-bottom: 25px;
            span{
                width: 90px;
                height: 30px;
                line-height: 30px;
                text-align: center;
                background: ${props => {
                    return Config.colour
                }};
                color: #fff;
                border-radius: 3px;
                display: inline-block;
                margin-right: 20px;
                cursor: pointer;
            }
        }
    }
    .xieyiBox{
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1000;
        background: rgba(0,0,0,.6);
        .xieyi{
            width: 900px;
            height: 600px;
            position: absolute;
            left: 50%;
            top: 50%;
            margin: -300px 0 0 -450px;
            background: #fff;
            .xieyiHead{
                height: 50px;
                line-height: 50px;
                position: relative;
                border-bottom: ${props => {
                    return "1px solid " + Config.colour
                }};
                span{
                    font-size: 18px;
                    padding: 0 20px;
                }
                img{
                    position: absolute;
                    top: 17px;
                    right: 15px;
                    height: 15px;
                    cursor: pointer;
                }
            }
            .xieyiCon{
                padding: 15px 20px;
                height: 530px;
                overflow-y: auto;
                pre{
                    word-wrap:break-word;
                }
            }
        }
    }
    .singleInput>img{
        cursor:pointer;
        height:40px;
    }
`

class SubmitVuln extends Component{
    constructor(props){
        super(props);
        this.state={
            projectId:this.props.param.match.params.id,
            xieyi:false,
            lookXieyi:false,
            projectDetail:{},
            submit:{},
            codeImg: `/ptp/captcha/?t=${new Date().getTime()}`
        }
    }
    componentDidMount(){
        Fetch.get("/ptp/project/"+this.state.projectId+"/").then(data=>{
            this.setState({
                projectDetail:data
            })
        })
        let submit=Local.getObject("vulnDetail");
        submit.code="";
        this.setState({
            submit:submit
        },()=>{
            let submit=this.state.submit;
            document.getElementsByClassName("desript")[0].innerHTML =submit.desript;
            document.getElementsByClassName("reiteration_steps")[0].innerHTML =submit.reiteration_steps;
            document.getElementsByClassName("restoration_plan")[0].innerHTML =submit.restoration_plan;
        })
    }
    componentWillUnmount(){
        this.setState=(state,callback)=>{
            return
        }
    }
    // 协议
    handleXieyi(){
        let submit=this.state.submit;
        submit.xieyi=!submit.xieyi
        this.setState({
            submit: submit
        })
    }
    // 查看协议
    handleLookXieYi(){
        let statement = require("../../json/vulnXieYi.txt");
        let init = {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'include'
        };
        fetch(statement, init).then((response) => {
            return response.text();
        }).then(mydata => {
            this.setState({
                lookXieyi: true,
                xieyiData: mydata
            })
        }).catch((res) => {

        });
    }
    handleCloseXieyi(){
        this.setState({
            lookXieyi: false
        })
    }
    submitVuln(){}
    //提交漏洞
    handleSubmitVuln(){
        let submit=this.state.submit;
        console.log(submit)
        if(submit.code===""){
            Alert("error","请输入验证码")
            return
        }
        let list={
            title:submit.title,
            categories:[submit.categoriesId],
            level:submit.levelId,
            desript:submit.desript,
            reiteration_steps:submit.reiteration_steps,
            restoration_plan:submit.restoration_plan,
            code:submit.code
        }
        if(!submit.xieyi){
            Alert("warn","请选择同意平台用户协议！")
            return
        }
        Fetch.post("/ptp/project/"+this.state.projectId+"/vuln/create/",{
            body:JSON.stringify(list),
            headers:{
                "Content-Type": "application/json;charset=UTF-8"
            }
        }).then(data=>{
            Alert("success","漏洞提交成功，请在个人中心我的提交关注提交状态！");
            history.push("/projexthall/detail/" + this.state.projectId);
        }).catch(err=>{
            if(err.detail){
                Alert("error", err.detail);
            }
            if(err.code){
                Alert("error", err.code);
            }
            this.handleClickCode();
        })
    }
    changeInput(value, name) {
        let submit=this.state.submit;
        submit[name]=value;
        this.setState({
            submit: submit
        })
    }
    // 预览
    handleLookVuln(){
        history.push("/projexthall/vuln/submit/"+this.state.projectId+"/1")
    }
    // 点击图形验证码
    handleClickCode(){
        this.setState({
            codeImg: `/ptp/captcha/?t=${new Date().getTime()}`
        })
    }
    focusInput(name) {
       
    }
    render(){
        const projectDetail=this.state.projectDetail;
        const submit=this.state.submit;
        return <Div>
            <DetailHeader submit={this.submitVuln.bind(this)} detail={projectDetail} showSubmit={false} projectId={this.state.projectId}/>
            <div className="form-submit">
                <p>预览漏洞</p>
                <div className="form-submit-content">
                    <div className="singleInput">
                        <span className="titBox">
                            漏洞标题：
                        </span>
                        <div style={{ display: "inline-block", width: "calc(100% - 90px)",marginLeft:"10px"}}>
                            {submit.title}
                        </div>
                    </div>
                    <div className="singleInput">
                        <span className="titBox">
                            漏洞类型：
                        </span>
                        <div style={{ display: "inline-block", width: "calc(100% - 90px)",marginLeft:"10px" }}>
                            {submit.categories}
                        </div>
                    </div>
                    <div className="singleInput">
                        <span className="titBox">
                            漏洞等级：
                        </span>
                        <div style={{ display: "inline-block", width: "calc(100% - 90px)",marginLeft:"10px" }}>
                            {submit.level}
                        </div>
                    </div>
                    <div className="singleInput">
                        <span className="titBox">
                            漏洞描述：
                        </span>
                        <div className="desript editerTable" style={{ display: "inline-block", width: "calc(100% - 90px)",marginLeft:"10px" }}>

                        </div>
                    </div>
                    <div className="singleInput">
                        <span className="titBox">
                            复现步骤：
                        </span>
                        <div className="reiteration_steps editerTable" style={{ display: "inline-block", width: "calc(100% - 90px)",marginLeft:"10px" }}>

                        </div>
                    </div>
                    <div className="singleInput">
                        <span className="titBox">
                            修复方案：
                        </span>
                        <div className="restoration_plan editerTable" style={{ display: "inline-block", width: "calc(100% - 90px)",marginLeft:"10px" }}>

                        </div>
                    </div>
                    <div className="singleInput">
                        <span className="titBox">
                            <span className="red-star">*</span>
                            验证码
                        </span>
                        <Input
                            type="text"
                            name="code"
                            height="40px"
                            width="81.5%"
                            defaultValue={submit.code}
                            maxLength="4"
                            bdColor={"#eee"}
                            focusInput={this.focusInput.bind(this)}
                            style={`margin-left:20px`}
                            placeholder="请输入验证码"
                            changeInput={this.changeInput.bind(this)}
                        />
                        <img src={this.state.codeImg} alt="" onClick={this.handleClickCode.bind(this)}/>
                    </div>
                    <div className="xieyi">
                        <span className="checkBox" onClick={this.handleXieyi.bind(this)}>
                            {
                                submit.xieyi && <img src={Zidui} alt="" />
                            }
                        </span>
                        <span className="xieyiText" >《同意雁行众测平台用户协议》</span>
                    </div>
                    <div className="btnBox">
                        <span onClick={this.handleSubmitVuln.bind(this)}>提交漏洞</span>
                        <span onClick={this.handleLookVuln.bind(this)}>返回编辑</span>
                    </div>
                </div>
            </div>
            {
                this.state.lookXieyi&&
                <div className="xieyiBox">
                    <div className="xieyi">
                        <div className="xieyiHead">
                            <span>雁行协议</span>
                            <img src={close} alt="" onClick={this.handleCloseXieyi.bind(this)}/>
                        </div>
                        <div className="xieyiCon">
                            <pre>{this.state.xieyiData}</pre>
                        </div>
                    </div>
                </div>
            }
        </Div>
    }
}
export default SubmitVuln