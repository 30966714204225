import React, { Component } from 'react';
import styled from 'styled-components';
import history from '../history';

const Div=styled.div`

`
class NoticeHeader extends Component{
    constructor(props){
        super(props)
        this.state={

        }
    }
    componentDidMount() {
        
    }
    componentWillUnmount = () => {
        this.setState=(state,callback)=>{
            return
        }
    };
    handleHref(type){
        if(type===1){
            history.push("/notice")
        }else{
            history.push("/notice/terrace")
        }
    }
    render(){
        return <Div>
                <div className="notice-header">
                    <span onClick={this.handleHref.bind(this,1)} className={this.props.type===1?"active":""}>活动公告<b></b></span>
                    <span onClick={this.handleHref.bind(this,2)} className={this.props.type===2?"active":""}>平台公告<b></b></span>
                </div>
        </Div>
    }
}
export default NoticeHeader
