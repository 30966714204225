import React, { Component } from 'react';
import styled from 'styled-components';
import WorldHeroes from '../../images/WorldHeroes.png';
import Podium from '../../images/podium.png';
import One from '../../images/1.png';
import Two from '../../images/2.png';
import Three from '../../images/3.png';
import { Avatar } from 'antd';
import Integral from '../../images/integral.png';
import history from '../history';

const Div=styled.div`
    height: 325px;
    overflow: hidden;
    .World-Heroes-left,.World-Heroes-right{
        display: inline-block;
        vertical-align:top; 
    }
    .World-Heroes-left{
        text-align:center; 
        margin-top:30px;
        position: relative;
        .text-detail{
            margin-left:75px;
            p{
                font-size:35px;
                color:#fff;
                line-height:60px; 
            }
        }
        .rank-toggle{
            text-align:left;
            position: absolute;
            padding-left:10px;
            margin-top: 87px;
            span{
                display: inline-block;
                text-align:center; 
                line-height:50px;
                font-size:20px;
                color:#fff; 
                width: 175px;
                background:#7059ef;
                cursor: pointer;
                border-radius:30px 70px 0 0;
                margin-left:10px;
            } 
            .active{
                background-image: linear-gradient(rgb(242,86,251) 0%,rgb(114,96,247) 100%);
            }
        }
    }
    .World-Heroes-right{
        margin-left:130px;
        position: relative;
        margin-top:10px;
        .podium{
            .podium-img{
                position: absolute;
            }
        }
        .number-two,.number-one,.number-three{
            display: inline-block;
            width: 130px;
            height: 109px;
            position: relative;
            text-align:center; 
        }
        .avatar{
            position: absolute;
            .rank-img{
                position: absolute;
            }
        }
        .user-info{
            position: absolute;
            width: 130px;
            text-align:center; 
            z-index: 2;
            span{
                color:#fff;
                display:inline-block;
                background:#da8ec7;
                padding: 2px 10px;
                border-radius: 15px;
                max-width: 110px;
                white-space: nowrap;
                word-break: break-word;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            div{
                line-height:20px;
                color:#fff;
                max-width: 110px;
                white-space: nowrap;
                word-break: break-word;
                overflow: hidden;
                margin:0 auto;
                text-overflow: ellipsis;
                img{
                    vertical-align:top;
                    margin-top:0px;
                    margin-right:2px; 
                } 
            }
        }
        .number-two{
            .avatar{
                top: 64px;
                left: 20px;
                span{
                    background: #fff;
                    position: absolute;
                    left: 17px;
                    top: 22px;
                }
            }
            .user-info{
                top:176px;
            }
        }
        .number-one{
            .avatar{
                left: 7px;
                span{
                    background: #fff;
                    position: absolute;
                    left: 14px;
                    top: 20px;
                }
            }
            .user-info{
                top:145px;
            }
        }
        .number-three{
            .avatar{
                top: 85px;
                left: 20px;
                span{
                    background: #fff;
                    position: absolute;
                    left: 17px;
                    top: 20px;
                }
            }
            .user-info{
                top:189px;
            }
        }
    }
`
class Seniority extends Component{
    constructor(props){
        super(props)
        this.state={

        }
    }
    componentDidMount() {
        
    }
    componentWillUnmount = () => {
        this.setState=(state,callback)=>{
            return
        }
    };
    handleHref(type){
        if(type===1){
            history.push("/heroes")
        }else{
            history.push("/heroes/month")
        }
    }
    render(){
        const data=this.props.data;
        return <Div>
            <div className="World-Heroes-left">
                <div className="text-detail">
                    <img src={WorldHeroes} alt=""/>
                    <p>--个人积分排行榜--</p>
                </div>
                <div className="rank-toggle">
                    <span onClick={this.handleHref.bind(this,1)} className={this.props.type===1?"active":""}>总榜</span>
                    <span onClick={this.handleHref.bind(this,2)} className={this.props.type===2?"active":""}>月榜</span>
                </div>
            </div>
            <div className="World-Heroes-right">
                <div className="ranking">
                    {/* 第二名用户 */}
                    <div className="number-two">
                        <div className="avatar">
                            <img className="rank-img" src={Two} alt=""/>
                            {
                                data!==undefined&&data.length>1&&
                                <Avatar src={data[1].photo} size={60} />
                            }
                        </div>
                        <div className="user-info">
                            {
                                data !== undefined && data.length > 1 &&
                                [<span key="1">{data[1].username}</span>,
                                <div key="2">
                                    <img src={Integral} alt="" />
                                    {data[1].points}
                                </div>]
                            }
                        </div>
                    </div>
                    {/* 第一名用户 */}
                    <div className="number-one">
                        <div className="avatar">
                            <img className="rank-img" src={One} alt=""/>
                            {
                                data !== undefined && data.length > 0 &&
                                <Avatar src={data[0].photo} size={87} />
                            }
                        </div>
                        <div className="user-info">
                            {
                                data !== undefined && data.length > 0 &&
                                [<span key="1">{data[0].username}</span>,
                                <div key="2">
                                    <img src={Integral} alt="" />
                                    {data[0].points}
                                </div>]
                            }
                        </div>
                    </div>
                    {/* 第三名用户 */}
                    <div className="number-three">
                        <div className="avatar">
                            <img className="rank-img" src={Three} alt=""/>
                            {
                                data !== undefined && data.length > 2 &&
                                <Avatar src={data[2].photo} size={52} />
                            }
                        </div>
                        <div className="user-info">
                            {
                                data !== undefined && data.length > 2 &&
                                [<span key="1">{data[2].username}</span>,
                                <div key="2">
                                    <img src={Integral} alt="" />
                                    {data[2].points}
                                </div>]
                            }
                        </div>
                    </div>
                </div>
                <div className="podium">
                    <img className="podium-img" src={Podium} alt=""/>
                </div>
            </div>
        </Div>
    }
}
export default Seniority
