import React, { Component } from 'react';
import styled from 'styled-components';
import PersonalLeft from './PersonalLeft';
import Config from '../../json/config.json';
import Page from '../Page';
import Fetch from '../Fetch';
import {toThousands} from '../toThousands';
import history from '../history';

const Div=styled.div`
    width: 1200px;
    margin:0 auto;
    padding:20px 0;
    .userinfo-left,.userinfo-right{
        display: inline-block;
        vertical-align:top; 
    }
    .userinfo-left{
        width: 280px;
    }
    .userinfo-right{
        width: 900px;
        margin-left:20px;
        background:#fff;
        padding:20px;
        .userinfo-right-title{
            line-height:30px; 
        }
        table{
            width: 100%;
            text-align:center; 
            border-right:1px solid #eee;
            border-bottom:1px solid #eee;
            thead{
                tr{
                    line-height:50px; 
                }
                background:#eee;
            }
            tbody{
                tr{
                    line-height:40px;
                    padding:0 5px; 
                }
            }
            td{
                border-top:1px solid #eee;
                border-left:1px solid #eee;
                white-space: nowrap;
                word-break: break-word;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .detail{
                color:${props=>{
                    return Config.colour
                }};
                cursor: pointer;
            }
        }
    }
    .top-grade{
        color:#ff9051
    }
`
class MySubmit extends Component{
    constructor(props){
        super(props)
        this.state={
            filter: {
                size:10,
                page:1
            },
            allCount:0,
            allData:[],
        }
    }
    componentDidMount() {
        this.getVulnList();
    }
    getVulnList(){
        let filter=this.state.filter;
        let str="size="+filter.size+"&page="+filter.page;
        Fetch.get("/ptp/vuln/list/my/?"+str).then(data=>{
            this.setState({
                allCount:data.count,
                allData:data.results
            })
        })
    }
    componentWillUnmount = () => {
        this.setState=(state,callback)=>{
            return
        }
    };
    //点击翻页
    getPages(page){
        let filter=this.state.filter;
        filter.page=page;
        this.setState({
            filter:filter
        },()=>{
            this.getVulnList();
        })
    }
    //点击查看漏洞详情
    handleLooKDetail(id){
        history.push("/userInfo/vuln/detail/"+id)
    }
    render(){
        const allData=this.state.allData;
        const filter=this.state.filter;
        return <Div>
            <div className="userinfo-left">
                <PersonalLeft type={2}/>
            </div>
            <div className="userinfo-right">
                <div className="userinfo-right-title">
                    我的提交
                </div>
                <table>
                    <thead>
                        <tr>
                            <th style={{width:"5%"}}>序号</th>
                            <th style={{width:"25%"}}>漏洞标题</th>
                            <th style={{width:"20%"}}>项目名称</th>
                            <th style={{width:"20%"}}>提交时间</th>
                            <th style={{width:"7.5%"}}>状态</th>
                            <th style={{width:"7.5%"}}>赏金</th>
                            <th style={{width:"7.5%"}}>积分</th>
                            <th style={{width:"7.5%"}}>操作</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            allData.map((val,key)=>{
                                return <tr key={key+"key"}>
                                <td>{key+1}</td>
                                <td title={val.title}>{val.title}</td>
                                <td title={val.project_name}>{val.project_name}</td>
                                <td>{val.create_time}</td>
                                <td className={(val.status===1||val.status===3)?"top-grade":""}>{val.status===1?"待确认":val.status===2?"已确认":val.status===3?"待补充":"已拒绝"}</td>
                                <td title={toThousands(val.bouns)}>{toThousands(val.bouns)}</td>
                                <td title={toThousands(val.points)}>{toThousands(val.points)}</td>
                                <td className="detail" onClick={this.handleLooKDetail.bind(this,val.id)}>详情</td>
                            </tr>
                            })
                        }
                    </tbody>
                </table>
                <Page
                    key="page"
                    upDown
                    pageNumber
                    allNumber
                    // jumpNumber
                    // selectNumber
                    nowpage={filter.page} //当前页
                    allPage={this.state.allCount}
                    everyPage={filter.size}
                    onClick={this.getPages.bind(this)}
                    onChange={this.getPages.bind(this)}
                />
            </div>
        </Div>
    }
}
export default MySubmit
