import React, { Component } from 'react';
import styled from 'styled-components';
import { NavLink} from "react-router-dom";
import Logo from '../../images/logo.png';
import history from '../history';
import Config from '../../json/config.json';
import { Avatar } from 'antd';
import realmName from '../../json/realmName.json';
import Fetch from '../Fetch';
import PubSub from 'pubsub-js';
import $ from 'jquery';

const Div=styled.div`
    height: 60px;
    background:#26282D;
    .home-header{
        width: 1200px;
        margin:0 auto;
        .logo{
            display: inline-block;
            line-height:60px; 
            vertical-align: top;
            img{
                vertical-align: middle;
                margin-top: -1px;
                cursor: pointer;
            }
        }
        .navigation{
            display: inline-block
            margin-left:40px;
            a{
                color: #fff;
                font-size:20px;
                display:inline-block;
                line-height:60px; 
                margin:0 20px;
                position:relative;
            }
            .active{
                b{
                    display: inline-block;
                    height: 5px;
                    background:#7059EF;
                    border-radius:5px;
                    width: 100%;
                    position: absolute;
                    bottom:2px;
                    left:0px;
                }
            }
        }
        .login-btn{
            float: right;
            line-height:60px; 
            button{
                margin-left:30px;
                font-size:16px;
                color: #fff;
                box-sizing:border-box;
                border:1px solid #fff;
                border-radius:5px;
                padding: 5px 20px;
                background:transparent; 
                cursor: pointer;
                line-height: 25px;
                :hover{
                    border:${props=>{
                        return "1px solid "+Config.colour
                    }};
                    background:${props=>{
                        return Config.colour
                    }};
                }
            }
        }
        .userinfo{
            float: right;
            margin-right:50px;
            margin-top:9px;
            cursor: pointer;
            position:relative;
            .drop-down{
                position:absolute;
                display: none;
                top: 40px;
                left: -25px;
                z-index:1;
                width: 100px;
                background:#fff;
                text-align:center; 
                border:1px solid #eee;
                border-radius:5px;
                padding: 10px 0;
                span{
                    display: inline-block;
                    width: 100%;
                    line-height:30px;
                    font-size:15px;
                    :hover{
                        color:${props=>{
                            return Config.colour
                        }}
                    } 
                }
            }
        }
        .userinfo:hover .drop-down{
            display: block;
        }
    }
`
class Header extends Component{
    constructor(props){
        super(props)
        this.state={
            isLogin:"",
            userPhoto:"",
            uuid:""
        }
    }
    componentDidMount() {
        this.pubsub_token = PubSub.subscribe("photo", (topic, data) => {
            this.setState({
                userPhoto: data.photo
            })
        })
        Fetch.get("/base/login/status/").then(data => {
            let isLogin = ""
            if (data.status === 1) {
                isLogin = true
            } else {
                isLogin = false
            }
            this.setState({
                isLogin: isLogin
            },()=>{
                if (this.state.isLogin){
                    Fetch.get("/base/user/info/").then(infodata => {
                        this.setState({
                            userPhoto: infodata.photo,
                        })
                    })
                    Fetch.get("/base/user/uuid/").then(data => {
                        if (data.status === 1) {
                            this.setState({
                                uuid: data.info
                            })
                            let that=this;
                            $.ajax({
                                url: realmName.sso + "/server_app/custom/is_login/",
                                type: "get",
                                data: { user_uuid:data.info },
                                success: function (res) {
                                    if(res.code===2){
                                        that.handleLogoutYun()
                                    }
                                }
                            })
                        }
                    })
                }
            })
        }).catch({})
    }
    componentWillUnmount = () => {
        this.setState=(state,callback)=>{
            return
        }
        PubSub.unsubscribe(this.pubsub_token);  
    };
    handleLogo(){
        history.push("/")
    }
    //跳转到个人中心
    handlePerson(){
        history.push("/userinfo")
    }
    // sso登录
    handleSsoLogin(){
        let href=window.location.href.replace("#","@");
        window.location.href=window.location.protocol + "//" + window.location.host + "/client/?next="+href;
    }
    // sso注册
    handleSsoRegiste(){
        window.open(realmName.sso + "/server_app/#/other/first/2")
    }
    // 退出
    handleLogout() {
        var that = this;
        this.handleLogoutYun()
        $.ajax({
            url: realmName.sso + "/server_app/logout/",
            type: "GET",
            data: { uuid: that.state.uuid },
            // dataType: "jsonp",    //跨域json请求一定是jsonp
            // jsonp: "callbackparam",    //跨域请求的参数名，默认是callback
            success: function (data) {

            }
        })
    }
    handleLogoutYun(){
        var that = this;
        $.ajax({
            url: "/base/logout/",
            type: "GET",
            success: function (data) {
                that.setState({
                    login: false
                })
                window.location.reload();
            }
        })
    }
    render(){
        const pathname = history.location.pathname;
        const isLogin =this.state.isLogin;
        return <Div>
            <div className="home-header">
                <div className="logo">
                    <img src={Logo} alt="" onClick={this.handleLogo.bind(this)}/>
                </div>
                <div className="navigation">
                    <a
                        href="/#/"
                        className={pathname==="/"?"active":""}
                    >
                        首页
                        <b></b>
                    </a>
                    <NavLink to="/projexthall" replace
                        className={pathname.indexOf("/projexthall")>-1?"active":""}
                    >
                        项目大厅
                        <b></b>
                    </NavLink>
                    <NavLink to="/heroes" replace
                        className={pathname.indexOf("/heroes")>-1?"active":""}
                    >
                        英雄榜
                        <b></b>
                    </NavLink>
                    <a href={realmName.dongming+"/#/shop/"} target="_blank" rel="noopener noreferrer">
                        兑换商城
                    </a>
                    <NavLink to="/help" replace
                        className={pathname.indexOf("/help")>-1?"active":""}
                    >
                        帮助中心
                        <b></b>
                    </NavLink>
                    <NavLink to="/notice" replace
                        className={pathname.indexOf("/notice")>-1?"active":""}
                    >
                        公告
                        <b></b>
                    </NavLink>
                </div>
                {
                    !isLogin?
                    <div className="login-btn">
                        <button onClick={this.handleSsoLogin.bind(this)}>登录</button>
                        <button onClick={this.handleSsoRegiste.bind(this)}>注册</button>
                    </div>
                    :
                    <div className="userinfo">
                        <Avatar src={this.state.userPhoto} size="large"/>
                        <div className="drop-down">
                            <span onClick={this.handlePerson.bind(this)}>个人中心</span>
                            <span onClick={this.handleLogout.bind(this)}>退出</span>
                        </div>
                    </div>
                }
            </div>
        </Div>
    }
}
export default Header
