import React, { Component } from 'react';
import styled from 'styled-components';
import Swiper from 'swiper/dist/js/swiper.js';
import Fetch from '../Fetch';
import Config from '../../json/config.json';

const Div=styled.div`
    margin-top:-6%;
    .swiper-container{
        height: 300px;
        #dian{
            .swiper-pagination-bullet{
                background:#90969A;
                width: 10px;
                height: 10px;
            }
            .swiper-pagination-bullet-active{
                background:${props => {
                    return Config.colour
                }}
            }
        }
        .swiper-wrapper{
            cursor: pointer;
        }
        .swiper-slide{
            width: 370px;
            height: 265px;
            position: relative;
            box-shadow: 2.5px 4.33px 3.64px 0.36px rgba(5,2,2,0.11);
            border-radius:10px;
            overflow: hidden;
            .activity-title{
                position: absolute;
                top:0;
                left:0;
                color:#fff;
                padding:0 20px;
                .activity-name{
                    font-weight:bold;
                    line-height:60px;
                    font-size:25px; 
                    position: relative;
                    word-break: break-word;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    width: 330px;
                    b{
                        position: absolute;
                        bottom:0;
                        left:0;
                        display: inline-block;
                        width: 30px;
                        height: 2px;
                        background:#fff;
                    }
                }
                p{
                    font-size18px;
                    line-height:30px; 
                }
            }
        }
    }
`
class Activity extends Component{
    constructor(props){
        super(props)
        this.state={
            data:[]
        }
    }
    componentDidMount() {
        this.getActivity();
    }
    mySwiper(){
        var mySwiper=new Swiper('.swiper-container', {
            loop: false,     //循环
            autoplay: {      //自动播放，注意：直接给autoplay:true的话，在点击之后不能再自动播放了
                delay: 2000,
                disableOnInteraction: true,    //户操作swiper之后，是否禁止autoplay。默认为true：停止。
            },
            slidesPerView:3,
            spaceBetween: 45,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,    // 允许点击跳转
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        })
        //鼠标移上轮播图停止轮播
        mySwiper.el.onmouseover = function(){
            mySwiper.autoplay.stop();
        }
        //鼠标移出轮播图开始轮播
        mySwiper.el.onmouseout = function(){
            mySwiper.autoplay.start();
        }
    }
    componentWillUnmount = () => {
        this.setState=(state,callback)=>{
            return
        }
    };
    getActivity(){
        Fetch.get("/base/activity/list/?nopage=0").then(data=>{
            this.setState({
                data:data
            },()=>{
                this.mySwiper()
            })
        })
    }
    handleHref(url){
        window.open(url)
    }
    render(){
        return <Div>
            <div className="swiper-container">
                <div className="swiper-wrapper">
                    {
                        this.state.data.map((val,key)=>{
                            return <div className="swiper-slide"
                                key={"key"+key}
                                // style={{background:"url("+val.photo+") no-repeat"}}
                                onClick={this.handleHref.bind(this,val.link)}
                            >
                               <img src={val.photo} alt=""/>
                               <div className="activity-title">
                                   <div className="activity-name" title={val.title}>{val.title}</div>
                               </div>
                            </div>
                        })
                    }
                </div>
                {/* <div id="dian" className='swiper-pagination'></div> */}
            </div>
        </Div>
    }
}
export default Activity
