import React, { Component } from 'react';
import styled from 'styled-components';
import Fetch from '../Fetch';

const Div=styled.div`
    padding:20px;
    border-radius:5px;
    background:#fff;
`
class NoticeDetail extends Component{
    constructor(props){
        super(props)
        this.state={
            noticeId:props.param.match.params.id,
        }
    }
    componentDidMount() {
        Fetch.get("/base/platform/notice/"+this.state.noticeId+"/").then(data=>{
            document.getElementsByClassName("notice-detail")[0].innerHTML=data.detail
        })
    }
    componentWillUnmount = () => {
        this.setState=(state,callback)=>{
            return
        }
    };
    render(){
        return <Div>
            <div className="notice-detail editerTable">

            </div>
        </Div>
    }
}
export default NoticeDetail
