import React, { Component } from 'react';
import styled from 'styled-components';
import Config from '../../json/config.json';
import Fetch from '../Fetch.js';
import {toThousands,Alert} from '../toThousands';
import Editer from '../Editer'; 
const Div = styled.div`
    width: 1200px;
    margin: 0 auto;
    .vulnHead{
        font-size: 18px;
        text-align: center;
        margin: 20px 0;
    }
    .vulnBody{
        .vulnLeft{
            width: 820px;
            vertical-align: top;
            display: inline-block;
            margin-right: 30px;
            .vulnTop{
                display: inline-block;
                padding: 25px;
                line-height: 30px;
                background: #fff;
                border-radius: 3px;
                margin-bottom: 10px;
                width: 100%;
                .vulnTit{
                    color: #999;
                    span{
                        color: #333;
                    }
                }
                .vulnDes{
                    .titBox{
                        display: inline-block;
                        width: 31%;
                        margin-right: 2%;
                        color: #999;
                        span{
                            color: #333;
                        }
                    }
                }
            }
            .vulnBottom{
                display: inline-block;
                width: 100%;
                .single{
                    line-height: 30px;
                    margin-bottom: 15px;
                    background: #fff;
                    border-radius: 3px;
                    padding-bottom: 20px;
                    .title{
                        text-align: center;
                        font-size: 16px;
                        line-height: 36px;
                        border-bottom: 1px solid #eee;
                    }
                    .content{
                        margin: 20px 0;
                        padding: 25px;
                        color: #444;
                    }
                    .buMessage{
                        .singleInput{
                            padding: 15px 25px;
                            .titBox{
                                display: inline-block;
                                vertical-align: top;
                                line-height: 16px;
                                position: relative;
                                .red-star{

                                }
                            }
                            .text-error{}
                        }
                    }
                    .submitBtn{
                        width: 160px;
                        height: 30px;
                        background: ${props => {
                            return Config.colour
                        }};
                        border-radius: 3px;
                        text-align: center;
                        line-height: 30px;
                        color: #fff;
                        margin: 0 auto;
                        cursor: pointer;
                    }
                }
            }
        }
        .vulnLeftAll{
            width:100%;
            vertical-align: top;
            display: inline-block;
            margin-right: 30px;
            .vulnTop{
                display: inline-block;
                padding: 25px;
                line-height: 30px;
                background: #fff;
                border-radius: 3px;
                margin-bottom: 10px;
                width: 100%;
                .vulnTit{
                    color: #999;
                    span{
                        color: #333;
                    }
                }
                .vulnDes{
                    .titBox{
                        display: inline-block;
                        width: 31%;
                        margin-right: 2%;
                        color: #999;
                        span{
                            color: #333;
                        }
                    }
                }
            }
            .vulnBottom{
                display: inline-block;
                width: 100%;
                .single{
                    line-height: 30px;
                    margin-bottom: 15px;
                    background: #fff;
                    border-radius: 3px;
                    padding-bottom: 20px;
                    .title{
                        text-align: center;
                        font-size: 16px;
                        line-height: 36px;
                        border-bottom: 1px solid #eee;
                    }
                    .content{
                        margin: 20px 0;
                        padding: 25px;
                        color: #444;
                    }
                    .buMessage{
                        .singleInput{
                            padding: 15px 25px;
                            .titBox{
                                display: inline-block;
                                vertical-align: top;
                                line-height: 16px;
                                position: relative;
                                .red-star{

                                }
                            }
                            .text-error{}
                        }
                    }
                    .submitBtn{
                        width: 160px;
                        height: 30px;
                        background: ${props => {
                            return Config.colour
                        }};
                        border-radius: 3px;
                        text-align: center;
                        line-height: 30px;
                        color: #fff;
                        margin: 0 auto;
                        cursor: pointer;
                    }
                }
            }
        }
        .vulnRight{
            display: inline-block;
            width: 300px;
            vertical-align: top;
            background: #fff;
            .topXian{
                width: 90%;
                border-bottom: 1px solid #eee;
                margin: 30px auto;
            }
            .RightTop{
                text-align: center;
                .hengXian{}
                .rightTitle{
                    font-size: 16px;
                    margin: 0 20px;
                }
            }
            .faxian{
                color: #999;
                text-align: center;
                margin-top: 20px;
                font-size: 13px;
            }
            .buOne{
                .shuXian{
                    height: 40px;
                    border-left: 1px solid #eee;
                    text-align: center;
                    margin: 0 auto;
                    width: 1px;
                }
                .trends{
                    text-align: center;
                    .trendsSingle{
                        img{
                            width: 40px;
                            height: 40px;
                            border-radius: 50%;
                        }
                        span{
                            margin-left: 10px;
                            font-size: 13px;
                            display: inline-block;
                            overflow: hidden;
                            word-break: break-word;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            max-width: 200px;
                            vertical-align: middle;
                        }
                    }
                    .time{
                        color: #999;
                        font-size: 12px;
                        margin-top: 10px;
                    }
                }
            }
        }
    }
    .edit-textarea{
        display: inline-block;
        vertical-align: top;
        margin: 0;
        padding: 0;
        margin-left: 20px;
        width: 640px;
    }
`
// 0预览  1详情  2补充
class VulnDetail extends Component {
    constructor(props) {
        super(props)
        this.state = {
            vulnId:this.props.param.match.params.id,
            vulnDetail:{},
            vulnLog:[]
        }
    }
    componentDidMount() {
        Fetch.get("/ptp/vuln/my/"+this.state.vulnId+"/ ").then(data=>{
            this.setState({
                vulnDetail:data
            },()=>{
                document.getElementsByClassName("desript")[0].innerHTML=data.desript;
                document.getElementsByClassName("reiteration_steps")[0].innerHTML=data.reiteration_steps;
                document.getElementsByClassName("restoration_plan")[0].innerHTML=data.restoration_plan;
                if(data.others.length>0){
                    for(var i=0;i<data.others.length;i++){
                        document.getElementsByClassName("others"+data.others[i].id)[0].innerHTML=data.others[i].content;
                    }
                }
            })
        })
        this.getVulnLog();
    }
    componentWillUnmount = () => {
        this.setState = (state, callback) => {
            return
        }
    }
    //获取漏洞动态
    getVulnLog(){
        Fetch.get("/ptp/vuln/my/"+this.state.vulnId+"/log/").then(data=>{
            this.setState({
                vulnLog:data
            })
        })
    }
    // 点击提交补充信息
    handleSubmit(){
        let desript = this.desript.handleContent()[0];
        let desripText = this.desript.handleContent()[1].replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&nbsp;/g,"").replace(/^\s*/g,"");
        if(desripText===""){
            Alert("error","请输入补充文本内容")
            return
        }
        let list={
            content: desript
        }
        Fetch.post("/ptp/vuln/my/"+this.state.vulnId+"/extra/add/",{
            body:JSON.stringify(list),
            headers:{
                "Content-Type": "application/json;charset=UTF-8"
            }
        }).then(data=>{
            if(data.status===1){
                Alert("success","漏洞补充成功，请在个人中心我的提交关注漏洞状态！");
                window.location.reload() 
            }else{
                Alert("error",data.info)
            }
        }).catch(err=>{
            Alert("error",err.detail)
        })
    }
    render() {
        const vulnDetail = this.state.vulnDetail;
        const vulnLog=this.state.vulnLog;
        return <Div>
            <div className="vulnHead">{vulnDetail.title}</div>
            <div className="vulnBody">
                <div className="vulnLeft">
                    <div className="vulnTop">
                        <div className="vulnTit">项目名称：<span title={vulnDetail.project_name}>{vulnDetail.project_name}</span></div>
                        <div className="vulnDes">
                            <span className="titBox">漏洞状态：<span>{vulnDetail.status===1?"待确认":vulnDetail.status===2?"已确认":vulnDetail.status===3?"待补充":"已拒绝"}</span></span>
                            <span className="titBox">赏金：<span>{toThousands(vulnDetail.bouns)}</span></span>
                            <span className="titBox">积分：<span>{toThousands(vulnDetail.points)}</span></span>
                            <span className="titBox">漏洞类型：<span>{vulnDetail.categories}</span></span>
                            <span className="titBox">漏洞等级：<span>{vulnDetail.level===1?"低危":vulnDetail.level===2?"中危":vulnDetail.level===3?"高危":"严重"}</span></span>
                            <span className="titBox">提交时间：<span>{vulnDetail.create_time}</span></span>
                        </div>
                    </div>
                    <div className="vulnBottom">
                        <div className="single">
                            <div className="title">漏洞描述</div>
                            <div className="content desript editerTable">
                                
                            </div>
                        </div>
                        <div className="single">
                            <div className="title">复现步骤</div>
                            <div className="content reiteration_steps editerTable">
                            </div>
                        </div>
                        <div className="single">
                            <div className="title">修复方案</div>
                            <div className="content restoration_plan editerTable">
                            </div>
                        </div>
                        {
                            vulnDetail.others!==undefined&&vulnDetail.others.map((val,key)=>{
                                return <div className="single" key={"key"+key}>
                                    <div className="title">{val.otype===1?"审核意见":"补充内容"}<span style={{display:"inline-block",marginLeft:'10px'}}>（{val.create_time}）</span></div>
                                    <div className={"content editerTable others"+val.id}>
                                        
                                    </div>
                                </div>
                            })
                        }
                        {
                            vulnDetail.status===3&&vulnDetail.project_status===2&&
                            <div className="single">
                                <div className="title">补充信息</div>
                                <div className="buMessage">
                                    <div className="singleInput">
                                        <span className="titBox">
                                            <span className="red-star">*</span>
                                            补充信息
                                        </span>
                                        <div className="edit-textarea">
                                            <Editer ref={div => this.desript = div}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="submitBtn" onClick={this.handleSubmit.bind(this)}>提交信息</div>
                            </div>
                        }
                    </div>
                </div>
                <div className="vulnRight">
                    <div className="topXian"></div>
                    <div className="RightTop">
                        <span className="hengXian">——</span>
                        <span className="rightTitle">漏洞动态</span>
                        <span className="hengXian">——</span>
                    </div>
                    <p className="faxian">发现/披露了漏洞</p>
                    {
                        vulnLog.map((val,key)=>{
                            return <div className="buOne" key={key+"key"}>
                            <p className="shuXian"></p>
                            <div className="trends">
                                <div className="trendsSingle">
                                    <img src={val.user.photo} alt="" />
                                    <span title={val.user.username}>{val.user.username}</span>
                                </div>
                                <p className="time">{val.create_time} {val.content}</p>
                            </div>
                        </div>
                        })
                    }
                    <div className="topXian"></div>
                </div>
                {/* <div id="id"></div> */}
            </div>
        </Div>
    }
}
export default VulnDetail
