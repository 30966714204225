import React, { Component } from 'react';
import styled from 'styled-components';
import Config from '../../json/config.json';
import Foot2wm from '../../images/foot2wm.png';
import $ from 'jquery';
const Div=styled.div`
    .footer-bottom{
        background:#100E1E;
        color:#fff;
        text-align:center;
        height: 50px;
        line-height:50px;  
        a{
            color:#fff;
            :hover{
                color:${props=>{
                    return Config.colour
                }}
            }
        }
    }
    .footer-top{
        background:#1C1936;
        min-height:100px;
        padding-top:20px;
        .footer-top-content{
            width: 1200px;
            margin:0 auto;
            .footer-top-content-left,.footer-top-content-content,.footer-top-content-right{
                display: inline-block;
                vertical-align:top; 
            }
            .footer-top-content-left
                img{
                    height: 270px;
                }
            }
            .footer-top-content-content{
                width: 400px;
                margin-left: 220px;
            }
            .footer-top-content-right{
                float: right;
            }
            .content-title{
                font-size:18px;
                font-weight:bold;
                line-height:50px;
                position: relative;
                color:#fff;
                b{
                    position: absolute;
                    display: inline-block;
                    width: 36px;
                    height: 2px;
                    background:#fff;
                    bottom:0px;
                    left:0px;
                }  
            }
            .content-link{
                color:#fff;
                line-height:50px;
                a{
                    color:#fff;
                    :hover{
                        color:${props=>{
                            return Config.colour
                        }}
                    }
                } 
            }
        }
    }
`
class Footer extends Component{
    constructor(props){
        super(props)
        this.state={

        }
    }
    componentDidMount() {
        $(".app-content").css({
            "min-height":$(window).height()-430
        })
    }
    componentWillUnmount = () => {
        this.setState=(state,callback)=>{
            return
        }
    };
    render(){
        return <Div>
            <div className="footer-top">
                <div className="footer-top-content">
                    <div className="footer-top-content-left">
                        <img  src={Foot2wm} alt=""/>
                    </div>
                    <div className="footer-top-content-content">
                        <div className="content-title">联系我们<b></b></div>
                        <div className="content-link">
                            电话：400-029-4789    029-88894789
                        </div>
                        <div className="content-link">
                            网址：<a href="http://www.seclover.com" target="_blank" rel="noopener noreferrer">http://www.seclover.com</a>
                        </div>
                        <div className="content-link">
                            邮箱：support@seclover.com
                        </div>
                        <div className="content-link">
                            地址：西安高新区软件新城天谷八路156号云汇谷C2楼17层
                        </div>
                    </div>
                    <div className="footer-top-content-right">
                        <div className="content-title">关于我们<b></b></div>
                        <div className="content-link">
                            <a href="http://www.seclover.com/static/pages/home.html?type=0" target="_blank" rel="noopener noreferrer">四叶草安全简介</a>
                        </div>
                        <div className="content-link">
                            <a href="http://www.seclover.com/static/pages/home.html?type=1" target="_blank" rel="noopener noreferrer">团队文化</a>
                        </div>
                        <div className="content-link">
                            <a href="http://www.seclover.com/static/pages/home.html?type=2" target="_blank" rel="noopener noreferrer">荣誉资质</a>
                        </div>
                        <div className="content-link">
                            <a href="http://www.seclover.com/static/pages/home.html?type=3" target="_blank" rel="noopener noreferrer">大事记</a>
                        </div>
                        <div className="content-link">
                            <a href="http://www.seclover.com/static/pages/home.html?type=4" target="_blank" rel="noopener noreferrer">人才招聘</a>
                        </div>
                    </div>
                    <div style={{clear:"both"}}></div>
                </div>
            </div>
            <div className="footer-bottom">
                版权所有 ©{new Date().getFullYear()} 西安四叶草信息技术有限公司 |
                <a href="/#/" target="_blank"> 陕ICP备14009494号-4</a>
            </div>
        </Div>
    }
}
export default Footer
