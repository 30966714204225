import React, { Component } from 'react';
import {
    Router,
    Route,
    Switch,
  } from 'react-router-dom';
import history from '../history';
import OnThelist from './OnThelist';
import TotalList from './TotalList';
import $ from 'jquery';

class WorlHeroes extends Component{
    constructor(props){
        super(props)
        this.state={

        }
    }
    componentDidMount() {
        $(".rankin-list").css({
            "height": $("body").width()>1200?$("body").width()*0.79:1200*0.79
        })
    }
    componentWillUnmount = () => {
        this.setState=(state,callback)=>{
            return
        }
    };
    render(){
        return <Router history={history}>
        <div className="rankin-list">
            <div>
                <Switch>
                    {/* 总榜 */}
                    <Route exact path="/heroes" component={(param)=>{
                        return <TotalList />
                    }}/>
                    {/* 月榜 */}
                    <Route path="/heroes/month" component={(param)=>{
                        return <OnThelist param={param}/>
                    }}/>
                </Switch>
            </div>
        </div>
    </Router>
    }
}
export default WorlHeroes
