import React, { Component } from 'react';
import styled from 'styled-components';
import DetailHeader from './DetailHeader';
import Input from "../Input";
import Select from "../Select";
import Editer from '../Editer'; 
import history from '../history';
import Zidui from '../../images/zidui.png'; 
import close from '../../images/close.png'; 
import Config from '../../json/config.json';
import Fetch from '../Fetch';
import Local from '../Local';
import {Alert} from '../toThousands';

const Div=styled.div`
    width: 1200px;
    margin:0 auto;
    .form-submit{
        margin-top:20px;
        background:#fff;
        border-radius:4px;
    }
    .form-submit>p{
        text-align:center;
        line-height: 40px;
        font-weight:bold;
        font-size:16px;
        border-bottom:1px solid #eee;
    }
    .form-submit-content{
        padding:20px 100px;
        .singleInput{
            margin-bottom: 15px;
            .titBox{
                position: relative;
                .red-star{
                    color: red;
                    top: -2px;
                }
            }
            .fuEditer{
                width: 900px;
                display: inline-block;
                vertical-align: top;
                margin-left: 20px;
            }
        }
        .singleInput>img{
            cursor:pointer;
            height:40px;
        }
        .xieyi{
            padding: 0 76px;
            margin-top: 20px;
            .checkBox{
                display: inline-block;
                width: 18px;
                height: 18px;
                border: 1px solid #666;
                vertical-align: middle;
                cursor: pointer;
                margin-top: -1px;
                img{
                    margin-top: -8px;
                }
            }
            .xieyiText{
                font-size: 13px;
                cursor: pointer;
                color: #7296FA;
            }
        }
        .btnBox{
            padding: 0 76px;
            margin-top: 10px;
            margin-bottom: 25px;
            span{
                width: 90px;
                height: 30px;
                line-height: 30px;
                text-align: center;
                background: ${props => {
                    return Config.colour
                }};
                color: #fff;
                border-radius: 3px;
                display: inline-block;
                margin-right: 20px;
                cursor: pointer;
            }
        }
    }
    .xieyiBox{
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1000;
        background: rgba(0,0,0,.6);
        .xieyi{
            width: 900px;
            height: 600px;
            position: absolute;
            left: 50%;
            top: 50%;
            margin: -300px 0 0 -450px;
            background: #fff;
            .xieyiHead{
                height: 50px;
                line-height: 50px;
                position: relative;
                border-bottom: ${props => {
                    return "1px solid " + Config.colour
                }};
                span{
                    font-size: 18px;
                    padding: 0 20px;
                }
                img{
                    position: absolute;
                    top: 17px;
                    right: 15px;
                    height: 15px;
                    cursor: pointer;
                }
            }
            .xieyiCon{
                padding: 15px 20px;
                height: 530px;
                overflow-y: auto;
                pre{
                    word-wrap:break-word;
                }
            }
        }
    }
    .text-error{
        color:#FF4747;
        font-size:12px;
        line-height:20px;
        padding-left: 86px;
    }
`

class SubmitVuln extends Component{
    constructor(props){
        super(props);
        this.state={
            projectId:props.param.match.params.id,
            where:props.param.match.params.where,
            type:[],
            level: [
                { id: 1, name: "低危" },
                { id: 2, name: "中危" },
                { id: 3, name: "高危" },
                { id: 4, name: "严重" }
            ],
            submit:{
                title:"",
                categories:"",
                categoriesId:"",
                level:"",
                levelId:"",
                desript:"",
                reiteration_steps:"",
                restoration_plan:"",
                xieyi:false,
                code:"",
            },
            error:{
                title:[],
                categories:[],
                level:[],
                desript:[],
                reiteration_steps:[],
                restoration_plan: [],
                code:[]
            },
            lookXieyi:false,
            projectDetail:{},
            loadEditor:false,
            codeImg:`/ptp/captcha/?t=${new Date().getTime()}`
        }
    }
    componentDidMount(){
        Fetch.get("/ptp/project/"+this.state.projectId+"/").then(data=>{
            this.setState({
                projectDetail:data
            })
        })
        Fetch.get("/ptp/vuln/categories/?nopage=0").then(data=>{
            this.setState({
                type:data
            })
        })
        if(this.state.where==="0"){
            Local.remove("vulnDetail");
            this.setState({
                loadEditor:true
            })
        }else{
            this.setState({
                submit:Local.getObject("vulnDetail")
            },()=>{
                this.setState({
                    loadEditor:true
                })
            })
        }
    }
    componentWillUnmount(){
        this.setState=(state,callback)=>{
            return
        }
    }
     // 点击图形验证码
     handleClickCode(){
        this.setState({
            codeImg: `/ptp/captcha/?t=${new Date().getTime()}`
        })
    }
    focusInput(name) {
        let error = this.state.error;
        error[name] = []
        this.setState({
            error: error
        })
    }
    changeInput(value, name) {
        let submit=this.state.submit;
        if (name === "title") {
            Object.assign(submit,{
                title: value
            })
        }
        if (name === "code") {
            Object.assign(submit,{
                code: value
            })
        }
        this.setState({
            submit: submit
        })
    }
    //下拉单个选项点击事件  value--选项id  param--input的name属性值  name--选项name
    selectStatus(value, param, name) {
        let submit = this.state.submit;
        submit[param] = name;
        submit[param+"Id"] = value;
        this.setState({
            submit: submit
        })
    }
    // 协议
    handleXieyi(){
        let submit=this.state.submit;
        submit.xieyi=!submit.xieyi
        this.setState({
            submit:submit
        })
    }
    // 查看协议
    handleLookXieYi(){
        let statement = require("../../json/vulnXieYi.txt");
        let init = {
            method: 'GET',
            mode: 'cors',
            cache: 'no-cache',
            credentials: 'include'
        };
        fetch(statement, init).then((response) => {
            return response.text();
        }).then(mydata => {
            this.setState({
                lookXieyi: true,
                xieyiData: mydata
            })
        }).catch((res) => {

        });
    }
    handleCloseXieyi(){
        this.setState({
            lookXieyi: false
        })
    }
    submitVuln(){}
    //点击预览与提交漏洞之前判断是否必填项都已填写 type判断是提交还是预览
    DetermineOptions(type){
        let submit=this.state.submit;
        let error=this.state.error;
        // 通过ref拿到handleContent()[0]的内容，将标签和宫格过滤掉，拿到text文本
        let desript = this.desript.handleContent()[0];
        let desripText = this.desript.handleContent()[1].replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&nbsp;/g,"").replace(/^\s*/g,"");
        let reiteration_steps = this.reiteration_steps.handleContent()[0];
        let reiteration_stepsext = this.reiteration_steps.handleContent()[1].replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&nbsp;/g,"").replace(/^\s*/g,"");
        let restoration_plan = this.restoration_plan.handleContent()[0];
        let restoration_planext = this.restoration_plan.handleContent()[1].replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&nbsp;/g,"").replace(/^\s*/g,"");
        if(submit.title===""){
            error.title=["请填写漏洞标题"]
            this.setState({
                error:error
            })
            return
        }
        if(submit.categories===""){
            error.categories=["请选择漏洞类型"]
            this.setState({
                error:error
            })
            return
        }
        if(submit.level===""){
            error.level=["请选择漏洞等级"]
            this.setState({
                error:error
            })
            return
        }
        if(submit.level===""){
            error.level=["请选择漏洞等级"]
            this.setState({
                error:error
            })
            return
        }
        if(desripText===""){
            Alert("error","请输入漏洞描述文本内容")
            return
        }
        if(reiteration_stepsext===""){
            Alert("error","请输入复现步骤文本内容")
            return
        }
        if(restoration_planext===""){
            Alert("error","请输入修复方案文本内容")
            return
        }
        submit.desript=desript;
        submit.reiteration_steps=reiteration_steps;
        submit.restoration_plan=restoration_plan;
        this.setState({
            submit:submit
        },()=>{
            if(type===0){
                this.handleSubmitVuln();
            }else{
                Local.setObject("vulnDetail",submit)
                this.handleLookVuln();
            }   
        })
    }
    //提交漏洞
    handleSubmitVuln(){
        let submit=this.state.submit;
        let error=this.state.error;
        if(submit.code===""){
            error.code=["请输入验证码"]
            this.setState({
                error:error
            })
            return
        }
        let list={
            title:submit.title,
            categories:[submit.categoriesId],
            level:submit.levelId,
            desript:submit.desript,
            reiteration_steps:submit.reiteration_steps,
            restoration_plan:submit.restoration_plan,
            code:submit.code
        }
        if(!submit.xieyi){
            Alert("warn","请选择同意平台用户协议！")
            return
        }
        Fetch.post("/ptp/project/"+this.state.projectId+"/vuln/create/",{
            body:JSON.stringify(list),
            headers:{
                "Content-Type": "application/json;charset=UTF-8"
            }
        }).then(data=>{
            Alert("success","漏洞提交成功，请在个人中心我的提交关注漏洞状态！");
            history.push("/projexthall/detail/" + this.state.projectId);
        }).catch(err=>{
            if(err.detail){
                Alert("error", err.detail);
            }
            if(err.title){
                error.title=err.title
            }
            if(err.categories){
                error.categories=err.categories
            }
            if(err.level){
                error.level=err.level
            }
            if(err.desript){
                error.desript=err.desript
            }if(err.code){
                error.code=err.code
            }
            if(err.reiteration_steps){
                error.reiteration_steps=err.reiteration_steps
            }
            if(err.restoration_plan){
                error.restoration_plan=err.restoration_plan
            }
            this.handleClickCode();
            this.setState({
                error:error
            })
        })
    }
    // 预览
    handleLookVuln(){
        history.push("/projexthall/vuln/preview/"+this.state.projectId)
    }
    render(){
        const submit = this.state.submit;
        const error = this.state.error;
        const projectDetail=this.state.projectDetail;
        const loadEditor=this.state.loadEditor;
        return <Div>
            <DetailHeader submit={this.submitVuln.bind(this)} detail={projectDetail} showSubmit={false} projectId={this.state.projectId}/>
            <div className="form-submit">
                <p>提交漏洞</p>
                <div className="form-submit-content">
                    <div className="singleInput">
                        <span className="titBox">
                            <span className="red-star">*</span>
                            漏洞标题
                        </span>
                        <Input
                            type="text"
                            name="title"
                            height="40px"
                            width="92%"
                            defaultValue={submit.title}
                            maxLength="100"
                            bdColor={error.title.length > 0 ? "#f00" : "#eee"}
                            focusInput={this.focusInput.bind(this)}
                            style={`margin-left:20px`}
                            placeholder="请输入漏洞标题"
                            changeInput={this.changeInput.bind(this)}
                        />
                        {
                            error.title.length > 0 &&
                            <p className="text-error">
                                {
                                    error.title.map((val, key, arr) => {
                                        return <span
                                            key={"key" + key}
                                        >
                                            {val}
                                        </span>
                                    })
                                }
                            </p>
                        }
                    </div>
                    <div className="singleInput">
                        <span className="titBox">
                            <span className="red-star">*</span>
                            漏洞类型
                        </span>
                        <div style={{ display: "inline-block", width: "92%" }}>
                            {
                                this.state.type &&
                                <Select
                                    name="categories"
                                    delSelect={this.selectStatus.bind(this)}
                                    default={submit.categories}
                                    width={"calc(100% - 20px)"}
                                    bdColor={error.categories.length > 0 ? "#f00" : "#eee"}
                                    style={`border-radius:0;margin-left:20px;display:flex`}
                                    sign="ah1"
                                    selectFocus={this.focusInput.bind(this)}
                                    list={this.state.type}
                                />
                            }
                        </div>
                        {
                            error.categories.length > 0 &&
                            <p className="text-error">
                                {
                                    error.categories.map((val, key, arr) => {
                                        return <span
                                            key={"key" + key}
                                        >
                                            {val}
                                        </span>
                                    })
                                }
                            </p>
                        }
                    </div>
                    <div className="singleInput">
                        <span className="titBox">
                            <span className="red-star">*</span>
                            漏洞等级
                        </span>
                        <div style={{ display: "inline-block", width: "92%" }}>
                            {
                                this.state.level &&
                                <Select
                                    name="level"
                                    delSelect={this.selectStatus.bind(this)}
                                    default={submit.level}
                                    width={"calc(100% - 20px)"}
                                    bdColor={error.level.length > 0 ? "#f00" : "#eee"}
                                    style={`border-radius:0;margin-left:20px;display:flex;`}
                                    sign="ah2"
                                    selectFocus={this.focusInput.bind(this)}
                                    list={this.state.level}
                                />
                            }
                        </div>
                        {
                            error.level.length > 0 &&
                            <p className="text-error">
                                {
                                    error.level.map((val, key, arr) => {
                                        return <span
                                            key={"key" + key}
                                        >
                                            {val}
                                        </span>
                                    })
                                }
                            </p>
                        }
                    </div>
                    <div className="singleInput">
                        <span className="titBox">
                            <span className="red-star">*</span>
                            漏洞描述
                        </span>
                        <div className="fuEditer">
                            {
                                loadEditor&&
                                <Editer ref={div => this.desript = div} defaultValue={submit.desript}/>
                            }
                        </div>
                        {
                            error.desript.length > 0 &&
                            <p className="text-error">
                                {
                                    error.desript.map((val, key, arr) => {
                                        return <span
                                            key={"key" + key}
                                        >
                                            {val}
                                        </span>
                                    })
                                }
                            </p>
                        }
                    </div>
                    <div className="singleInput">
                        <span className="titBox">
                            <span className="red-star">*</span>
                            复现步骤
                        </span>
                        <div className="fuEditer">
                            {
                                loadEditor&&
                                <Editer ref={div => this.reiteration_steps = div} defaultValue={submit.reiteration_steps}/>
                            }
                        </div>
                        {
                            error.reiteration_steps.length > 0 &&
                            <p className="text-error">
                                {
                                    error.reiteration_steps.map((val, key, arr) => {
                                        return <span
                                            key={"key" + key}
                                        >
                                            {val}
                                        </span>
                                    })
                                }
                            </p>
                        }
                    </div>
                    <div className="singleInput">
                        <span className="titBox">
                            <span className="red-star">*</span>
                            修复方案
                        </span>
                        <div className="fuEditer">
                            {
                                loadEditor&&
                                <Editer ref={div => this.restoration_plan = div} defaultValue={submit.restoration_plan}/>
                            }
                        </div>
                        {
                            error.restoration_plan.length > 0 &&
                            <p className="text-error">
                                {
                                    error.restoration_plan.map((val, key, arr) => {
                                        return <span
                                            key={"key" + key}
                                        >
                                            {val}
                                        </span>
                                    })
                                }
                            </p>
                        }
                    </div>
                    <div className="singleInput">
                        <span className="titBox">
                            <span className="red-star">*</span>
                            验证码
                        </span>
                        <Input
                            type="text"
                            name="code"
                            height="40px"
                            width="81.5%"
                            defaultValue={submit.code}
                            maxLength="4"
                            bdColor={error.code.length > 0 ? "#f00" : "#eee"}
                            focusInput={this.focusInput.bind(this)}
                            style={`margin-left:20px`}
                            placeholder="请输入验证码"
                            changeInput={this.changeInput.bind(this)}
                        />
                        <img src={this.state.codeImg} alt="" onClick={this.handleClickCode.bind(this)}/>
                        {
                            error.code.length > 0 &&
                            <p className="text-error">
                                {
                                    error.code.map((val, key, arr) => {
                                        return <span
                                            key={"key" + key}
                                        >
                                            {val}
                                        </span>
                                    })
                                }
                            </p>
                        }
                    </div>
                    <div className="xieyi">
                        <span className="checkBox" onClick={this.handleXieyi.bind(this)}>
                            {
                                submit.xieyi && <img src={Zidui} alt="" />
                            }
                        </span>
                        <span className="xieyiText" onClick={this.handleLookXieYi.bind(this)}>《同意雁行众测平台用户协议》</span>
                    </div>
                    <div className="btnBox">
                        <span onClick={this.DetermineOptions.bind(this,0)}>提交漏洞</span>
                        <span onClick={this.DetermineOptions.bind(this,1)}>预览</span>
                    </div>
                </div>
            </div>
            {
                this.state.lookXieyi&&
                <div className="xieyiBox">
                    <div className="xieyi">
                        <div className="xieyiHead">
                            <span>雁行协议</span>
                            <img src={close} alt="" onClick={this.handleCloseXieyi.bind(this)}/>
                        </div>
                        <div className="xieyiCon">
                            <pre>{this.state.xieyiData}</pre>
                        </div>
                    </div>
                </div>
            }
        </Div>
    }
}
export default SubmitVuln