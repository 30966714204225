import React, { Component } from 'react';
import styled from 'styled-components';
import PersonalLeft from './PersonalLeft';
import { Avatar } from 'antd';
import Config from '../../json/config.json';
import underReview from '../../images/underReview.png';
import RealVerify from './RealVerify';
import Fetch from '../Fetch';
import $ from 'jquery';
import PubSub from 'pubsub-js'
import realmName from '../../json/realmName.json';

const Div=styled.div`
    width: 1200px;
    margin:20px auto;
    .userinfo-left,.userinfo-right{
        display: inline-block;
        vertical-align:top; 
    }
    .userinfo-left{
        width: 280px;
    }
    .userinfo-right{
        width: 900px;
        margin-left:20px;
        background:#fff;
        .edit-avatar{
            text-align:center; 
            width: 120px;
            height: 120px;
            margin: 30px auto;
            position: relative;
            .edit-tips{
                position: absolute;
                top: 40px;
                left: 105px;
                width: 300px;
                color:#999;
            }
            .upload-avatar{
                position: absolute;
                top:0px;
                left:0px;
                bottom:0px;
                right:0px;
                background:rgba(0,0,0,.5);
                border-radius:50%;
                cursor: pointer;
                line-height:120px;
                text-align:center;  
                font-weight:bold;
                color:#fff;
                span{
                    font-size:16px; 
                }
            }
        }
        .basicInfor,.real-info{
            padding:0 20px;
        }
        .basicInfor{
            border-bottom:1px solid #eee;
        }
        .single-message{
            margin-bottom:30px;
            .title{
                display: inline-block;
                text-align:right;
                width: 63px;
                color:#999;
                line-height:40px;  
                vertical-align:top; 
            }
            .single-message-right{
                display: inline-block;
                vertical-align:top;
                line-height:40px;  
                padding-left:20px;
                display: inline-block;
                width: 710px;
                word-break: break-word;
                .not-edit{
                    span{
                        color:#333;
                    }
                    b{
                        display: inline-block;
                        margin-left:20px;
                        color:#ff7426;
                        cursor: pointer;
                    }
                    .emailInput{
                        width: 300px;
                        line-height: 34px;
                        border: 1px solid #ccc;
                        padding: 0 10px;
                        word-break: break-word;
                        overflow: hidden;
                    }
                    .emailBtn{
                        display: inline-block;
                        width: 50px;
                        text-align: center;
                        color: #333;
                        cursor: pointer;
                    }
                    .sure{
                        color: #ff7426;
                    }
                }
            }
        }
        .base-title{
            line-height:50px;
            font-size:16px;
            font-weight:bold;  
            div{
                display: inline-block;
                margin-left:20px;
                i{
                    color:#22ac38;
                    font-size:18px;
                }
                span{
                    color:#ff5555;
                    font-weight:normal;
                    padding-left:10px;
                }
            }
        }
        .real-info{
            padding-bottom:30px;
            .unverified{
                padding-left:100px;
                div{
                    display: inline-block;
                    text-align:center;
                    span{
                        line-height:50px;
                        color: #ff4747;
                        font-size:18px;
                    } 
                    button{
                        color: #fff;
                        background:${props=>{
                            return Config.colour
                        }};
                        padding:5px 25px;
                        cursor: pointer;
                        border-radius:5px;
                    }
                }
            }
            .underReview{
                display: inline-block;
                text-align:center;
                margin-left:50px;
                p{
                    line-height:30px; 
                } 
            }
            .verified{
                margin-top:20px;
            }
        }
    }
    .error{
        color: red;
    }
`
class UserInfo extends Component{
    constructor(props){
        super(props)
        this.state={
            audit_status:1, //1、未审核 2、审核中 3、审核通过
            approve:false,
            infoData:"",
            userPhoto:"",
            photoError:"",
            emailEdit:false,
            email:"",
            emailError:"",
            address:"",
            uuid:""
        }
        this.Person=this.Person.bind(this)
    }
    componentDidMount() {
        this.Person()
        this.pubsub_token = PubSub.subscribe("RenZheng", (topic, data) => {
            if(data===true){
                this.Person()
            }
        })
        Fetch.get("/base/user/uuid/").then(data => {
            if (data.status === 1) {
                this.setState({
                    uuid: data.info
                })
            }
        })
    }
    Person(){
        Fetch.get("/base/user/info/").then(data => {
            this.setState({
                infoData: data,
                userPhoto: data.photo,
                email: data.email,
                address: data.address
            },()=>{
                PubSub.publish("photo", this.state.infoData); 
            })
        })
    }
    componentWillUnmount = () => {
        this.setState=(state,callback)=>{
            return
        }
        PubSub.unsubscribe(this.pubsub_token);  
    };
    //点击上传头像
    handleUpload(){
        document.getElementById("upload").click()
    }
    //上传头像
    handleChangeImg(){
        // 拿到图片路径
        let file = document.getElementById("upload").files[0];
        if(file===undefined){
            return
        }
        // 上传文件必须使用ajax
        // 实时替换图片在页面上
        if (file !== undefined) {
            // if (window.createObjectURL !== undefined) { // basic
            //     Object.assign(infoData, {
            //         photo: window.createObjectURL(file)
            //     })
            // } else if (window.URL !== undefined) { // mozilla(firefox)
            //     Object.assign(infoData, {
            //         photo: window.URL.createObjectURL(file)
            //     })
            // } else if (window.webkitURL !== undefined) { // webkit or chrome
            //     Object.assign(infoData, {
            //         photo: window.webkitURL.createObjectURL(file)
            //     })
            // }
            // 上传文件必须使用ajax
            var form = document.getElementById('formImg');
            let formData = new FormData(form);
            var that = this;
            $.ajax({
                url: "/base/user/update/",
                type: "PATCH",
                data: formData,
                contentType: false,
                processData: false,
                success: function (res) {
                    that.Person()
                    that.setState({
                        photoError:""
                    })
                },
                error: function (err) {
                    if (err.responseJSON.photo) {
                        that.setState({
                            photoError: err.responseJSON.photo
                        })
                    }
                }
            });
        }
    }
    handleChangeEmail(event){
        var value = event.target.value.replace(/(^\s*)|(\s*$)/g, "");
        this.setState({
            email: value
        })
    }
    handleFocusEmail(){
        this.setState({
            emailError: ""
        })
    }
    handleEditEmail(){
        this.setState({
            emailEdit: true
        })
    }
    handleCloseEmail(){
        this.setState({
            emailEdit: false
        })
    }
    handleSureEmail(){
        let email=this.state.email;
        var form = document.getElementById('formImg');
        let formData = new FormData(form);
        formData.append("email", email);
        var that = this;
        $.ajax({
            url: "/base/user/update/",
            type: "PATCH",
            data: formData,
            contentType: false,
            processData: false,
            success: function (res) {
                that.setState({
                    emailEdit: false
                })
                that.Person()
            },
            error: function (err) {
                if (err.responseJSON.email) {
                    that.setState({
                        emailError: err.responseJSON.email
                    })
                }
            }
        });
    }
    handleChangeAddress(event){
        var value = event.target.value.replace(/(^\s*)|(\s*$)/g, "");
        this.setState({
            address: value
        })
    }
    handleFocusAddress() {
        this.setState({
            addressError: ""
        })
    }
    handleSureAddress(){
        let address = this.state.address;
        var form = document.getElementById('formImg');
        let formData = new FormData(form);
        formData.append("address", address);
        var that = this;
        $.ajax({
            url: "/base/user/update/",
            type: "PATCH",
            data: formData,
            contentType: false,
            processData: false,
            success: function (res) {
                that.setState({
                    addressEdit: false
                })
                that.Person()
            },
            error: function (err) {
                if (err.responseJSON.address) {
                    that.setState({
                        addressError: err.responseJSON.address
                    })
                }
            }
        });
    }
    handleEditAddress(){
        this.setState({
            addressEdit: true
        })
    }
    handleCloseAddress() {
        this.setState({
            addressEdit: false
        })
    }
    //关闭实名认证弹框
    handleCloseBox(){
        this.setState({
            approve:false
        })
    }
    //点击实名认证
    handleClickReal(){
        this.setState({
            approve:true
        })
    }
    handleEditPass(){
        window.open(realmName.sso + "/server_app/#/person/editpass/first/2/" + this.state.uuid)
    }
    handleEditPhone(){
        window.open(realmName.sso + "/server_app/#/person/editphone/first/2/" + this.state.uuid)
    }
    render(){
        const approve=this.state.approve;
        const infoData = this.state.infoData;
        return <Div>
            <div className="userinfo-left">
                <PersonalLeft type={1}/>
            </div>
            <div className="userinfo-right">
                <div className="edit-avatar">
                    <Avatar src={infoData.photo} size={120}/>
                    <div className="edit-tips">
                        只支持JPG、PNG、GIG，大小不超过5M <br/>
                        建议尺寸为100 X 100
                    </div>
                    <div className="upload-avatar" onClick={this.handleUpload.bind(this)}>
                        <form id="formImg">
                            <input name="photo" type="file" id="upload" 
                                accept="image/png, image/jpeg, image/gif, image/jpg"
                                style={{display:"none"}} 
                                onChange={this.handleChangeImg.bind(this)}/>
                        </form>
                        <span>更改头像</span>
                    </div>
                    <div className="error">{this.state.photoError}</div>
                </div>
                <div className="basicInfor">
                    <div className="base-title">基本信息</div>
                    <div className="single-message">
                        <span className="title">用户名</span>
                        <div className="single-message-right">
                            <div className="not-edit">
                                <span>{infoData.username}</span>
                            </div>
                        </div>
                    </div>
                    <div className="single-message">
                        <span className="title">登录密码</span>
                        <div className="single-message-right">
                            <div className="not-edit">
                                <span>********</span>
                                <b onClick={this.handleEditPass.bind(this)}>修改</b>
                            </div>
                        </div>
                    </div>
                    <div className="single-message">
                        <span className="title">手机号</span>
                        <div className="single-message-right">
                            <div className="not-edit">
                                <span>{infoData.phone!==""?infoData.phone:"暂未填写"}</span>
                                <b onClick={this.handleEditPhone.bind(this)}>修改</b>
                            </div>
                        </div>
                    </div>
                    <div className="single-message">
                        <span className="title">微信昵称</span>
                        <div className="single-message-right">
                            <div className="not-edit">
                                <span>{infoData.nickname !== "" ? infoData.nickname : "暂未填写"}</span>
                                {/* <b>修改</b> */}
                            </div>
                        </div>
                    </div>
                    <div className="single-message">
                        <span className="title">邮箱</span>
                        <div className="single-message-right">
                            {
                                this.state.emailEdit?
                                [<div key="1" className="not-edit">
                                    <input className="emailInput" name="email" type="text"
                                        value={this.state.email} 
                                        placeholder="请输入邮箱"
                                        onChange={this.handleChangeEmail.bind(this)}
                                        onFocus={this.handleFocusEmail.bind(this)}
                                        />
                                    <span className="emailBtn" onClick={this.handleCloseEmail.bind(this)}>取消</span>
                                    <span className="emailBtn sure" onClick={this.handleSureEmail.bind(this)}>确认</span>
                                </div>,
                                <div key="2" className="error">{this.state.emailError}</div>]
                                :
                                <div className="not-edit">
                                    <span>{infoData.email !== "" ? infoData.email : "暂未填写"}</span>
                                    <b onClick={this.handleEditEmail.bind(this)}>修改</b>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="single-message">
                        <span className="title">邮递地址</span>
                        <div className="single-message-right">
                            {
                                this.state.addressEdit ?
                                    [<div key="1" className="not-edit">
                                        <input className="emailInput" name="address" type="text"
                                            value={this.state.address}
                                            placeholder="请输入邮寄地址"
                                            onChange={this.handleChangeAddress.bind(this)}
                                        />
                                        <span className="emailBtn" onClick={this.handleCloseAddress.bind(this)}>取消</span>
                                        <span className="emailBtn sure" onClick={this.handleSureAddress.bind(this)}>确认</span>
                                    </div>,
                                    <div key="2" className="error">{this.state.addressError}</div>]
                                    :
                                    <div className="not-edit">
                                        <span>{infoData.address !== "" ? infoData.address : "暂未填写"}</span>
                                        <b onClick={this.handleEditAddress.bind(this)}>修改</b>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
                <div className="real-info">
                    <div className="base-title">
                        实名信息
                        {
                            infoData.status===2&&
                            <div>
                                <i className="fa fa-check-circle"></i>
                                <span>已通过审核，审核时间：{infoData.check_time}</span>
                            </div>
                        }
                    </div>
                    {/* 未认证 */}
                    {
                        infoData.status===4&&
                        <div className="unverified">
                            <div>
                                <span>您未实名认证</span><br/>
                                <button onClick={this.handleClickReal.bind(this)}>认证</button>
                            </div>
                        </div>
                    }
                    {/* 审核中 */}
                    {
                        infoData.status===1&&
                        <div className="underReview">
                            <div>
                                <img src={underReview} alt=""/>
                                <p>资料审核中</p>
                            </div>
                        </div>
                    }
                    {/* 已拒绝 */}
                    {
                        infoData.status === 3 &&
                        <div className="unverified">
                            <div>
                                <span>您已被拒绝，请重新申请</span><br />
                                <button onClick={this.handleClickReal.bind(this)}>认证</button>
                            </div>
                        </div>
                    }
                    {/* 审核通过 */}
                    {
                        infoData.status===2&&
                        <div className="verified">
                            <div className="single-message">
                                <span className="title">真实姓名</span>
                                <div className="single-message-right">
                                    <div className="not-edit">
                                        <span>{infoData.realname}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="single-message">
                                <span className="title">身份证</span>
                                <div className="single-message-right">
                                    <div className="not-edit">
                                        <span>{infoData.id_card}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            {
                approve&&
                <RealVerify close={this.handleCloseBox.bind(this)}
                    />
            }
        </Div>
    }
}
export default UserInfo
