import React, { Component } from 'react';
import styled from 'styled-components';
import Seniority from './Seniority';
import Config from '../../json/config.json';
import RankList from './RankList';
import Fetch from '../Fetch';

const Div=styled.div`
    width: 1200px;
    margin:0 auto;
    .rank-table{
        background: #fff;
        border-radius:20px;
        .rank-table-header{
            background:#f5f5f5;
            padding:30px 10px;
            border-radius:20px 20px 0 0;
            .text{
                position: relative;
                .fontWeight{
                    font-size:20px;
                    font-weight:bold; 
                }
                span{
                    vertical-align:bottom;
                    display: inline-block; 
                }
                span:nth-child(2){
                    margin-left:20px;
                    color:#999;
                }
                b{
                    display: inline-block;
                    position: absolute;
                    width: 40px;
                    height: 3px;
                    background: ${props=>{
                        return Config.colour
                    }}
                    bottom:0px;
                    left:0px;
                }
            }
        }
    }
`
class TotalList extends Component{
    constructor(props){
        super(props)
        this.state={
            yearData:[]
        }
    }
    componentDidMount() {
        Fetch.get("/base/total/rank/").then((data) => {
            this.setState({
                yearData: data.results
            })
        })
    }
    componentWillUnmount = () => {
        this.setState=(state,callback)=>{
            return
        }
    };
    render(){
        return <Div>
            <Seniority type={1} data={this.state.yearData}/>
            <div className="rank-table">
                <div className="rank-table-header">
                    <div className="text">
                        <span className="fontWeight">TOP10英雄榜</span>
                        <span>根据累计积分排名</span>
                        <b></b>
                    </div>
                </div>
                <RankList data={this.state.yearData}/>
            </div>
        </Div>
    }
}
export default TotalList
