import React, { Component } from 'react';
import styled from 'styled-components';
import Project from '../../images/project.png';
import Rank from '../../images/rank.png';
import Vuln from '../../images/vuln.png';
import {toThousands} from '../toThousands';
import Fetch from '../Fetch';
import Config from '../../json/config.json';

const Div=styled.div`
    width: 1200px;
    margin:0 auto;
    text-align:center;
    .data-display{
        display: inline-block;
        width: 400px;
        margin-top:5%;
        .number{
            color: ${props => {
                return Config.colour
            }};
            font-size:30px;
            font-weight:bold;  
            line-height:40px; 
        }
        p{
            color:#999999;
            font-size:16px;
            line-height:20px; 
        }
    }
`
class Dynamic extends Component{
    constructor(props){
        super(props)
        this.state={
            data:{}
        }
    }
    componentDidMount() {
        Fetch.get("/ptp/system/config/").then(data=>{
            this.setState({
                data:data
            })
        })
    }
    componentWillUnmount = () => {
        this.setState=(state,callback)=>{
            return
        }
    };
    render(){
        const data=this.state.data;
        return <Div>
            <div className="data-display">
                <img src={Project} alt=""/>
                <div className="number">{toThousands(data.project_number)}</div>
                <p>项目数量</p>
            </div>
            <div className="data-display">
                <img src={Vuln} alt=""/>
                <div className="number">{toThousands(data.vuln_number)}</div>
                <p>漏洞数量</p>
            </div>
            <div className="data-display">
                <img src={Rank} alt=""/>
                <div className="number">{toThousands(data.payment_bouns)}</div>
                <p>已发放赏金</p>
            </div>
        </Div>
    }
}
export default Dynamic
