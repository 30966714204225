import React, { Component } from 'react';
import styled from 'styled-components';
import {toThousands} from '../toThousands';
import Fetch from '../Fetch';
import history from '../history';
const Div=styled.div`
    .look-more{
        text-align:center;
        span{
            font-size:18px; 
            color:#999;
            cursor: pointer;
            line-height:30px; 
        }
    }
`
class HighEvents extends Component{
    constructor(props){
        super(props)
        this.state={
            hotData:[]
        }
    }
    componentDidMount() {
        Fetch.get("/ptp/project/list/hot/").then(data=>{
            this.setState({
                hotData:data
            })
        })
    }
    componentWillUnmount = () => {
        this.setState=(state,callback)=>{
            return
        }
    };
    handleLookMore(){
        history.push("/projexthall")
    }
    render(){
        const hotData=this.state.hotData;
        return <Div>
            {
                hotData.map((val,key)=>{
                    return  <div className="item-project" key={key+"key"}>
                    <div className="project-photo">
                        <img src={val.example_photo} alt=""/>
                        {/* not-online 即将上线class  underway 进行中  finished 已完成 */}
                        <span className={val.status===1?"not-online":val.status===2?"underway":"finished"}>{val.status===1?"即将上线":val.status===2?"进行中":"已结束"}</span>
                    </div>
                    <div className="project-content">
                        <div className="project-name" style={{cursor:"auto"}} title={val.name}>{val.name}</div>
                        <div className="project-bonus">奖金池：{toThousands(val.bouns_pool)}</div>
                        <div className="project-time">期限：{val.start_time} - {val.end_time}</div>
                    </div>
                    {/* <div className="project-detail"></div> */}
                </div>
                })
            }
           <div className="look-more"><span onClick={this.handleLookMore.bind(this)}>点击查看更多＞＞</span></div>
        </Div>
    }
}
export default HighEvents
