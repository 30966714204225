import React, { Component } from 'react';
import styled from 'styled-components';
import Swiper from 'swiper/dist/js/swiper.js';
import Fetch from '../Fetch';
import Config from '../../json/config.json';

const Div=styled.div`
.swiper-container1{
    margin-bottom:50px;
    position: relative;
    overflow: hidden;
    list-style: none;
    padding: 0;
    z-index: 1;
    .dian{
        .swiper-pagination-bullet{
            background:#90969A;
            width: 10px;
            height: 10px;
        }
        .swiper-pagination-bullet-active{
            background:${props => {
                return Config.colour
            }}
        }
    }
    .swiper-wrapper{
        cursor: pointer;
    }
    .swiper-slide{
        position: relative;
        text-align:center; 
        // width: 120px;
        img{
            max-width: 100%;
        }
        .activity-title{
            .activity-name{
                font-size:13px; 
                color:#999;
                line-height: 20px;
                word-break: break-word;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                width: 100%;
            }
        }
    }
}
`
class Partner extends Component{
    constructor(props){
        super(props)
        this.state={
            partnerList:[]
        }
    }
    componentDidMount() {
        this.getPartner();
    }
    Swiper(){
        var mySwiper1=new Swiper('.swiper-container1', {
            loop: false,     //循环
            autoplay: {      //自动播放，注意：直接给autoplay:true的话，在点击之后不能再自动播放了
                delay: 2000,
                disableOnInteraction: true,    //户操作swiper之后，是否禁止autoplay。默认为true：停止。
            },
            slidesPerView:10,
            spaceBetween: 30,
            pagination: {
                el: '.swiper-pagination',
                clickable: false,    // 允许点击跳转
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        })
        //鼠标移上轮播图停止轮播
        mySwiper1.el.onmouseover = function(){
            mySwiper1.autoplay.stop();
        }
        //鼠标移出轮播图开始轮播
        mySwiper1.el.onmouseout = function(){
            mySwiper1.autoplay.start();
        }
    }
    getPartner(){
        Fetch.get("/base/partner/list/?nopage=0").then(data=>{
            this.setState({
                partnerList:data
            },()=>{
                this.Swiper()
            })
        })
    }
    componentWillUnmount = () => {
        this.setState=(state,callback)=>{
            return
        }
    };
    render(){
        const partnerList=this.state.partnerList;
        return <Div>
             <div className="swiper-container1">
                <div className="swiper-wrapper">
                    {
                        partnerList.map((val,key)=>{
                            return <div className="swiper-slide" key={"key"+key}>
                                <img src={val.photo} alt=""/>
                                <div className="activity-title">
                                    <div className="activity-name">{val.partner_name}</div>
                                </div>
                            </div>
                        })
                    }
                </div>
                {/* <div className='swiper-pagination dian'></div> */}
            </div>
        </Div>
    }
}
export default Partner
