import React, { Component } from 'react';
import styled from 'styled-components';
import Config from '../../json/config.json';
import ProjectNotice from './ProjectNotice';
import DetailHeader from './DetailHeader';
import history from '../history';
import Fetch from '../Fetch.js';

const Div=styled.div`
    width: 1200px;
    margin:0 auto;
    .detail-left,.detail-right{
        background:#fff;
        min-height:100px;
        box-sizing:border-box;
    }
    .detail-left,.detail-right{
        display: inline-block;
        vertical-align:top; 
    }
    .detail-content{
        margin-top:20px;
    }
    .detail-left{
        width: 990px;
        margin-right:10px; 
    }
    .detail-left-header{
        line-height:50px;
        color:#000;
        font-size:18px;
        text-align:center;  
        border-bottom:1px solid #eee;
        font-weight:bold; 
    }
    .detail-right{
        width: 200px;
        .single-notice{
            position: relative;
            padding:0 20px;
            margin:10px 0;
            color:#666;
            cursor: pointer;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            b{
                position: absolute;
                display: inline-block;
                width: 5px;
                height: 5px;
                border-radius:50%;
                top:9px;
                left:9px;
                background-color:${props=>{
                    return Config.colour
                }}
            }
        }
    }
    .project-detail{
        padding:10px 20px;
        padding-bottom:30px;
        .title{
            font-size:16px;
            color:#000;
            line-height:40px; 
            position: relative;
            padding-left:20px;
            b{
                position: absolute;
                display: inline-block;
                width: 8px;
                height: 8px;
                border-radius:50%;
                background: ${props=>{
                    return Config.colour
                }};
                top:16px;
                left:0px;
            }
        }
        .content{
            padding-left:20px;
            line-height:20px; 
            pre{
                word-break: break-all;
                white-space:pre-wrap; 
            }
        }
        .project-tip{
            padding-left:20px;
            line-height: 40px;
            font-size:16px;
            color:#000;
            span{
                font-size:16px;
                color:${props=>{
                    return Config.colour
                }};
                cursor: pointer;
            }
        }
    }
    .detail-content{
        margin-bottom:30px;
    }
`
class WorlHeroes extends Component{
    constructor(props){
        super(props)
        this.state={
            projectId: this.props.param.match.params.id,
            lookNotice:false,
            projectDetail:{},
            projectNatice:[],
            nativeDetail:{}
        }
    }
    componentDidMount() {
        Fetch.get("/ptp/project/"+this.state.projectId+"/").then(data=>{
            this.setState({
                projectDetail:data
            })
        })
        Fetch.get("/ptp/project/"+this.state.projectId+"/notice/list/").then(data=>{
            this.setState({
                projectNatice:data
            })
        })
    }
    componentWillUnmount = () => {
        this.setState=(state,callback)=>{
            return
        }
    };
    handleLookNotice(val){
        this.setState({
            lookNotice:true,
            nativeDetail:val
        })
    }
    closeNotice(){
        this.setState({
            lookNotice:false
        })
    }
    submitVuln(){
        history.push("/projexthall/vuln/submit/"+this.state.projectId+"/0")
    }
    render(){
        const lookNotice=this.state.lookNotice;
        const projectDetail=this.state.projectDetail;
        const projectNatice=this.state.projectNatice;
        return <Div>
            <DetailHeader submit={this.submitVuln.bind(this)} showSubmit={true} projectId={this.state.projectId} detail={projectDetail}/>
            <div className="detail-content">
                <div className="detail-left">
                    <div className="detail-left-header">项目详情</div>
                    <div className="project-detail">
                        <p className="title"><b></b>描述</p>
                        <div className="content">
                            {
                                projectDetail.status===2?
                                <pre>
                                {projectDetail.describe}
                                </pre>
                                :<div>项目已结束</div>
                            }
                        </div>
                        <p className="title"><b></b>规则</p>
                        <div className="content">
                        {
                            projectDetail.status===2?
                            <pre>
                               {projectDetail.rule}
                            </pre>
                            :<div>项目已结束</div>
                        }
                       </div>
                        {
                            projectDetail.status===2&&
                            <div className="project-tip">
                                还未加入，请<span onClick={this.submitVuln.bind(this)}>提交漏洞</span>
                            </div>
                        }
                    </div>
                </div>
                <div className="detail-right">
                    <div className="detail-left-header">最新公告</div>
                    {
                        projectNatice.map((val,key)=>{
                            return <div className="single-notice" onClick={this.handleLookNotice.bind(this,val)} key={"key"+key}>
                            <b></b>
                            {val.content}
                        </div>
                        })
                    }
                    {
                        projectNatice.length===0&&
                        <div style={{fontSize:"18px",lineHeight:"100px",color:"#666",textAlign:"center"}}>暂无最新公告</div>
                    }
                </div>
            </div>
            {
                lookNotice&&
                <ProjectNotice close={this.closeNotice.bind(this)} detail={this.state.nativeDetail}/>
            }
        </Div>
    }
}
export default WorlHeroes
