import React, { Component } from 'react';
import styled from 'styled-components';
import NoticeHeader from './NoticeHeader';
import Page from '../Page';
import Fetch from '../Fetch';

const Div=styled.div`
   
`
class System extends Component{
    constructor(props){
        super(props)
        this.state={
            filter:{
                page:1,
                size:10
            },
            allCount:0,
            allData:[]
        }
    }
    componentDidMount() {
        this.noticeList();
    }
    noticeList(){
        let filter=this.state.filter;
        let str="page="+filter.page+"&size="+filter.size
        Fetch.get("/base/activity/notice/list/?"+str).then(data=>{
            this.setState({
                allCount:data.count,
                allData:data.results
            })
        })
    }
    componentWillUnmount = () => {
        this.setState=(state,callback)=>{
            return
        }
    };
    //翻页
    getPages(page){
        let filter=this.state.filter;
        filter.page=page;
        this.setState({
            filter:filter
        },()=>{
            this.noticeList();
        })
    }
    handleLook(url){
        window.open(url)
    }
    render(){
        const allData=this.state.allData;
        return <Div>
            <div className="notice-body">
                <NoticeHeader type={1}/>
                {
                    allData.map((val,key)=>{
                        return <div className="notice-content" key={"key"+key}>
                        <div className="notice-content-header">
                            <span className="title" title={val.title}>{val.title}</span>
                            <span className="time">{val.create_time}</span>
                        </div>
                        <div className="notice-content-detail">
                            <pre>
                                {val.content}
                                <span onClick={this.handleLook.bind(this,val.link)}>查看详情>></span>
                            </pre>
                        </div>
                    </div>
                    })
                }
                {
                    allData.length===0&&
                    <div className="no-notice">暂无最新公告</div>
                }
            </div>
            <div className="notice-page">
                <Page
                    key="page"
                    upDown
                    pageNumber
                    // allNumber
                    // jumpNumber
                    // selectNumber
                    nowpage={this.state.filter.page} //当前页
                    allPage={this.state.allCount}
                    everyPage={this.state.filter.size}
                    onClick={this.getPages.bind(this)}
                    onChange={this.getPages.bind(this)}
                />
            </div>
        </Div>
    }
}
export default System
