import React, { Component } from 'react';
import styled from 'styled-components';
import {Avatar} from 'antd';
import Config from '../../json/config.json';
import history from '../history';
import $ from 'jquery';
import Fetch from '../Fetch';
import PubSub from 'pubsub-js'

const Div=styled.div`
    .basic-info{
        background:#fff;
        padding-top:30px;
        border-radius:5px;
        .user-avatar{
            text-align:center; 
            padding: 0 20px;
            p{
                line-height:25px;
                margin-top:10px;
                font-weight:bold;
                font-size:14px;  
                margin-bottom:0px;
                overflow: hidden;
                word-break: break-word;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .signature{
                span{
                    font-size:13px;
                    line-height:20px; 
                    color:#999;
                    cursor: pointer;
                }
            }
            .edit-signature{
                resize:none;
                width: 100%;
                height: 60px;
                padding:5px 10px;
                font-size:13px;
                border:1px solid #eceef2;
            }
        }
        .integral{
            padding:30px 0;
            .single-integral{
                display: inline-block;
                vertical-align:top; 
                width: 33%;
                b,span{
                    display: inline-block;
                    text-align:center;
                    line-height:20px;  
                    width: 100%;
                    padding: 0 10px;
                    white-space: nowrap;
                    word-break: break-word;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                span{
                    color:#999;
                }
            }
            .line{
                display: inline-block;
                height: 30px;
                width: 1px;
                background:#f4f4f4;
                margin-top: 5px;
            }
        }
    }
    .base-toggle{
        background:#fff;
        margin-top:10px;
        border-radius:5px;
        span{
            display: inline-block;
            width: 100%;
            line-height:50px; 
            font-size:15px;
            padding:0 20px;
            cursor: pointer;
        }
        span:nth-child(1){
            border-bottom:1px solid #eee;
        }
        .active{
            font-weight:bold;
            position: relative;
            b{
                display: inline-block;
                position: absolute;
                height: 20px;
                width: 2px;
                top:15px;
                left:0;
                background:${props=>{
                    return Config.colour
                }}
            } 
        }
    }
`
class PersonalLeft extends Component{
    constructor(props){
        super(props)
        this.state={
            edit:false,
            userText:"",
            userTextError:"",
            infoData:""
        }
        this.Person = this.Person.bind(this)
    }
    componentDidMount() {
        this.Person()
        this.pubsub_token = PubSub.subscribe("photo", (topic, data) => {
            this.setState({
                infoData:data
            })
        })
    }
    Person() {
        Fetch.get("/base/user/info/").then(data => {
            this.setState({
                infoData: data,
                userPhoto: data.photo,
                email: data.email,
                address: data.address,
                userText: data.person_sign
            })
        })
    }
    componentWillUnmount = () => {
        this.setState=(state,callback)=>{
            return
        }
        PubSub.unsubscribe(this.pubsub_token);  
    };
    // 双击编辑
    handleEdit(){
        this.setState({
            edit:true
        },()=>{
            document.getElementById('edit-signature').focus()
        })
    }
    //取消编辑并且保存
    handleCloseEdit(){
        this.setState({
            edit:false
        },()=>{
            let userText = this.state.userText;
            var form = document.getElementById('formImg');
            let formData = new FormData(form);
            formData.append("person_sign", userText);
            var that = this;
            $.ajax({
                url: "/base/user/update/",
                type: "PATCH",
                data: formData,
                contentType: false,
                processData: false,
                success: function (res) {
                    that.setState({
                        userTextEdit: false
                    })
                    that.Person()
                },
                error: function (err) {
                    if (err.responseJSON.userText) {
                        that.setState({
                            userTextError: err.responseJSON.userText
                        })
                    }
                }
            });
        })
    }
    handleChangeText(event){
        var value = event.target.value.replace(/(^\s*)|(\s*$)/g, "");
        this.setState({
            userText:value
        },()=>{
            if(this.state.userText.length===50){
                this.setState({
                    userTextError:"签名最多可输50个字"
                })
            }
        })
    }
    handleFocusText(){
        this.setState({
            userTextError:""
        })
    }
    //跳转
    handleSkip(type){
        if(type===1){
            history.push("/userinfo")
        }else if(type===2){
            history.push("/userinfo/submit")
        }
    }
    render(){
        const edit=this.state.edit;
        const infoData = this.state.infoData;
        return <Div>
            <div className="basic-info">
                <div className="user-avatar">
                    <Avatar src={infoData.photo} size={80}/>
                    <p title={infoData.username}>{infoData.username}</p>
                    <div className="signature">
                        {
                            !edit&&
                            <span onDoubleClick={this.handleEdit.bind(this)}>{infoData.person_sign !== "" ? infoData.person_sign :"这个人很懒，暂未设置签名"}</span>
                        }
                        {
                            edit&&
                            [<textarea key="1" maxLength="50"
                                onBlur={this.handleCloseEdit.bind(this)} 
                                className="edit-signature" 
                                id="edit-signature" 
                                value={this.state.userText}
                                onChange={this.handleChangeText.bind(this)}
                                onFocus={this.handleFocusText.bind(this)}
                                ></textarea>,
                            <div key="2" className="error">{this.state.userTextError}</div>]
                        }
                    </div>
                </div>
                <div className="integral">
                    <div className="single-integral" title={"积分："+infoData.points}>
                        <b>{infoData.points}</b>
                        <span>积分</span>
                    </div>
                    <b className="line"></b>
                    <div className="single-integral" title={"排名："+infoData.ranking}>
                        <b>{infoData.ranking}</b>
                        <span>排名</span>
                    </div>
                    <b className="line"></b>
                    <div className="single-integral" title={"赏金："+infoData.bouns}>
                        <b>{infoData.bouns}</b>
                        <span>赏金</span>
                    </div>
                </div>
            </div>
            <div className="base-toggle">
                <span className={this.props.type===1?"active":""} onClick={this.handleSkip.bind(this,1)}><b></b>资料与账号</span>
                <span className={this.props.type===2?"active":""} onClick={this.handleSkip.bind(this,2)}><b></b>我的提交</span>
            </div>
        </Div>
    }
}
export default PersonalLeft
