

const initialState = {
    show:true
}
export default function(state=initialState, action) {
    switch (action.type) {
      case "SET_SHOW": {
        return {
          ...state,
          show:action.show
        }
      }
      default:
        return state;
    }
}