import React, { Component } from 'react';
import {
    Router,
    Route,
    Switch,
  } from 'react-router-dom';
import history from '../history';
import MySubmit from './MySubmit';
import UserInfo from './UserInfo';
import VulnDetail from './VulnDetail';

class ProjectHall extends Component{
    constructor(props){
        super(props)
        this.state={

        }
    }
    componentDidMount() {
        
    }
    componentWillUnmount = () => {
        this.setState=(state,callback)=>{
            return
        }
    };
    render(){
        return <Router history={history}>
        <div>
            <div>
                <Switch>
                    {/* 个人信息 */}
                    <Route exact path="/userInfo" component={(param)=>{
                        return <UserInfo />
                    }}/>
                    {/* 我的提交 */}
                    <Route path="/userInfo/submit" component={(param)=>{
                        return <MySubmit param={param}/>
                    }}/>
                    {/* 漏洞详情 */}
                    <Route path="/userInfo/vuln/detail/:id" component={(param)=>{
                        return <VulnDetail param={param}/>
                    }}/>
                </Switch>
            </div>
        </div>
    </Router>
    }
}
export default ProjectHall
