import React, { Component } from 'react';
import {
    Router,
    Route,
    Switch,
  } from 'react-router-dom';
import history from '../history';
import System from './System';
import Terrace from './Terrace';
import Detail from './NoticeDetail';
import '../../css/notice.css';

class WorlHeroes extends Component{
    constructor(props){
        super(props)
        this.state={

        }
    }
    componentDidMount() {
        
    }
    componentWillUnmount = () => {
        this.setState=(state,callback)=>{
            return
        }
    };
    render(){
        return <Router history={history}>
        <div className="notice">
            <div>
                <Switch>
                    {/* 系统公告 */}
                    <Route exact path="/notice" component={(param)=>{
                        return <System />
                    }}/>
                    {/* 平台公告 */}
                    <Route path="/notice/terrace" component={(param)=>{
                        return <Terrace param={param}/>
                    }}/>
                    {/* 公告详情 */}
                    <Route path="/notice/detail/:id" component={(param)=>{
                        return <Detail param={param}/>
                    }}/>
                </Switch>
            </div>
        </div>
    </Router>
    }
}
export default WorlHeroes
