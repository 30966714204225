import React, { Component } from 'react';
import styled from 'styled-components';
import Seniority from './Seniority';
import Config from '../../json/config.json';
import RankList from './RankList';
import Fetch from '../Fetch';

const Div=styled.div`
    width: 1200px;
    margin:0 auto;
    .rank-table{
        background: #fff;
        border-radius:20px;
        .rank-table-header{
            background:#f5f5f5;
            padding:30px 10px;
            border-radius:20px 20px 0 0;
            .text,.time-screen,.single-btn{
                display: inline-block;
                vertical-align:top; 
            }
            .text{
                position: relative;
                .fontWeight{
                    font-size:20px;
                    font-weight:bold; 
                }
                span{
                    vertical-align:bottom;
                    display: inline-block; 
                }
                span:nth-child(2){
                    margin-left:20px;
                    color:#999;
                }
                b{
                    display: inline-block;
                    position: absolute;
                    width: 40px;
                    height: 3px;
                    background: ${props=>{
                        return Config.colour
                    }}
                    bottom:0px;
                    left:0px;
                }
            }
            .time-screen{
                float: right;
                .show-time{
                    display:inline-block;
                    vertical-align:top; 
                    position: relative;
                    padding-right:20px;
                    background:${props=>{
                        return Config.colour
                    }};
                    color:#fff;
                    cursor: pointer;
                    margin-right:10px;
                    line-height:35px; 
                    width: 90px;
                    text-align:center; 
                    i{
                        position: absolute;
                        right:9px;
                        top:10px;
                    }
                }
            }
            .single-btn{
                position: relative;
                .dropdown-menu{
                    position: absolute;
                    top: 100%;
                    left: 0;
                    width: 107px;
                    min-width: 107px;
                    z-index: 1000;
                    background-color:#fff;
                    border: 1px solid rgba(0,0,0,.15);
                    border-radius: 4px;
                    box-shadow: 0 6px 12px rgba(0,0,0,.175);
                    padding: 5px 0;
                    margin: 2px 0 0;
                    font-size: 14px;
                    text-align: left;
                    span{
                        display:block;
                        line-height:30px;
                        cursor: pointer; 
                        padding: 0 20px;
                        :hover{
                            color:${props=>{
                                return Config.colour
                            }}
                        }
                    }
                }
            }
            
        }
    }
`
class OnThelist extends Component{
    constructor(props){
        super(props)
        this.state={
            yearList:[],
            monthList:[1,2,3,4,5,6,7,8,9,10,11,12],
            nowYear: "",
            nowMonth:"",
            selectYear:false,
            selectMonth:false,
            monthData:[]
        }
        this.AllData = this.AllData.bind(this)
    }
    componentDidMount() {
        Fetch.get("/base/date/").then((data)=>{
            this.setState({
                yearList:data.year,
                nowMonth:data.month,
                nowYear: data.year[0]
            },()=>{
                this.AllData()
            })
        })
        this._isMounted=true;
        document.addEventListener("click", this.yearClick.bind(this), false);
    }
    yearClick(ev) {
        if(this._isMounted){
            var e = ev || window.event; //浏览器兼容性
            var elem = e.target || e.srcElement;
            while (elem) {
                //循环判断至跟节点，防止点击的是div子元素
                if (elem.className!==undefined&&(elem.className.indexOf("selectYear")!==-1 || elem.className.indexOf("selectMonth")!==-1)) {
                    return;
                }else{
                    elem = elem.parentNode;
                }
            }
            this.setState({
                selectYear: false,
                selectMonth:false
            });
        }
    }
    componentWillUnmount = () => {
        this.setState=(state,callback)=>{
            return
        }
        this._isMounted=false;
        document.removeEventListener("click", this.yearClick.bind(this), false);
    };
    // 月榜数据
    AllData(){
        var list = { year: this.state.nowYear, month: this.state.nowMonth}
        Fetch.get("/base/month/rank/?",list).then(data=>{
            this.setState({
                monthData:data
            })
        })
    }
    //点击下拉
    handleShowDown(type){
        let selectYear=this.state.selectYear;
        let selectMonth=this.state.selectMonth;
        if(type===1){
            this.setState({
                selectYear:!selectYear,
                selectMonth:false
            })
        }else{
            this.setState({
                selectMonth:!selectMonth,
                selectYear:false
            })
        }
    }
    // 点击选择年份
    handleYear(year){
        this.setState({
            nowYear:year
        },()=>{
            this.AllData()
        })
    }
    // 点击选择月份
    handleMonth(month){
        this.setState({
            nowMonth:month
        },()=>{
            this.AllData()
        })
    }
    render(){
        const yearList=this.state.yearList;
        const monthList=this.state.monthList;
        const selectYear=this.state.selectYear;
        const selectMonth=this.state.selectMonth;
        return <Div>
            <Seniority type={2} data={this.state.monthData}/>
            <div className="rank-table">
                <div className="rank-table-header">
                    <div className="text">
                        <span className="fontWeight">TOP10英雄榜</span>
                        <span>根据累计积分排名</span>
                        <b></b>
                    </div>
                    <div className="time-screen">
                        <div className="single-btn">
                            <span className="show-time selectYear" onClick={this.handleShowDown.bind(this,1)}>
                                {this.state.nowYear}年
                                <i className="fa fa-caret-down"></i>
                            </span>
                            {
                                selectYear&&
                                <div className="dropdown-menu">
                                    {
                                        yearList.map((val,key)=>{
                                            return <span key={"key"+key}
                                                onClick={this.handleYear.bind(this, val)}
                                            >{val}年</span>
                                        })
                                    }
                                </div>
                            }
                        </div>
                        <div className="single-btn">
                            <span className="show-time selectMonth" onClick={this.handleShowDown.bind(this,2)}>
                                {this.state.nowMonth}月
                                <i className="fa fa-caret-down"></i>
                            </span>
                            {
                                selectMonth&&
                                <div className="dropdown-menu">
                                    {
                                        monthList.map((val,key)=>{
                                            return <span key={"key"+key}
                                                onClick={this.handleMonth.bind(this,val)}
                                            >{val}月</span>
                                        })
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <RankList data={this.state.monthData}/>
            </div>
        </Div>
    }
}
export default OnThelist
