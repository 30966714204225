import React, { Component } from 'react';
import {
    Router,
    Route,
    Switch,
  } from 'react-router-dom';
  import history from '../history';
  import ProjectList from './ProjectList';
  import ProjectDetail from './ProjectDetail';
  import SubmitVuln from './SubmitVuln';
  import VulnDetail from './PreviewVuln';

class ProjectHall extends Component{
    constructor(props){
        super(props)
        this.state={

        }
    }
    componentDidMount() {
        
    }
    componentWillUnmount = () => {
        this.setState=(state,callback)=>{
            return
        }
    };
    render(){
        return <Router history={history}>
        <div>
            <div>
                <Switch>
                    {/* 项目大厅 */}
                    <Route exact path="/projexthall" component={(param)=>{
                        return <ProjectList />
                    }}/>
                    {/* 项目详情 */}
                    <Route path="/projexthall/detail/:id" component={(param)=>{
                        return <ProjectDetail param={param}/>
                    }}/>
                    {/* 提交漏洞 SubmitVuln*/}
                    <Route path="/projexthall/vuln/submit/:id/:where" component={(param)=>{
                        return <SubmitVuln param={param}/>
                    }}/>
                    {/* 漏洞预览*/}
                    <Route path="/projexthall/vuln/preview/:id" component={(param) => {
                        return <VulnDetail param={param} />
                    }} />
                </Switch>
            </div>
        </div>
    </Router>
    }
}
export default ProjectHall
